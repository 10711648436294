import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { persistor, store } from './store';
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./config/azureAuthConfig";
var root = ReactDOM.createRoot(document.getElementById("root"));
root.render(React.createElement(React.StrictMode, null,
    React.createElement(MsalProvider, { instance: msalInstance },
        React.createElement(Provider, { store: store, children: React.createElement(PersistGate, { loading: null, persistor: persistor },
                React.createElement(App, null)) }))));
