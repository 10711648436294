export var api_urls = {
    CREATE: '/admin/account/create',
    LOGIN: '/admin/login',
    LOGOUT: '/admin/logout',
    FORGOT_PASSWORD: '/admin/forgot-password',
    RESET_PASSWORD: '/admin/reset-password',
    RESEND_PASSWORD_LINK: '/admin/resend-password-link',
    VERIFY_OTP: '/admin/verify-otp',
    REFRESH_TOKEN: '/admin/refresh-token',
    USER_PROFILE: '/admin/auth/user-profile',
    VENDOR_LIST: '/vendor/list',
    DEALER_LIST: '/vendor/users',
    ADMIN_LIST: '/admin/list',
    DEALER_PROG_ASSOCIATION_LIST: '/dealer/program/list',
    GET_DEALER_NAME: '/account/dealer-info',
    PROGRAM_MANAGEMENT_LIST: '/program/list',
    UPDATE_USER: '/vendor/update-user',
    DEACTIVATE_USER: '/vendor/deactive-user',
    ROLES_LIST: '/role/list',
    CREATE_ADMIN: '/admin/create',
    CREATE_DEALER_PROG_ASSOCIATION: '/dealer/program/association',
    UPDATE_ADMIN: '/admin/update',
    CREATE_PROGRAM: '/program/create',
    UPDATE_PROGRAM: 'program/update',
    ADMIN_IMPERSONATE: '/admin/impersonate',
    CREATE_DEALER: '/vendor/create',
    DEALER_DETAILS_BY_ID: '/vendor/config-details',
    UPDATE_DEALER_CONFIG: '/vendor/update-config',
    DELETE_DEALER_FILES: '/vendor/remove-logos',
    MARKETING_CONTENT_SAVE: '/marketing-content/save',
    MARKETING_CONTENT_LIST: '/marketing-content/list',
    DELETE_NOTIFICATION_DATA: '/marketing-content/delete',
    PROGRAMS_LIST: '/program/list',
    SYNC_DEALER_PROG_ASSOCIATION: '/dealer/sync',
    SYNC_PROGRAM: '/program/sync',
    GET_DESCRIPTION_OPTIONS: '/program/sync/list',
    DELETE_PROGRAM: '/program/delete/',
    DELETE_DEALER_PROG_ASSOCIATION: '/dealer/delete',
    SAVE_OR_UPDATE_FIELD_CONFIG: '/vendor/field-config',
    GET_FIELD_CONFIG_DATA: '/vendor/field-config',
    GET_FAILED_APPLICATIONS: '/admin/application/failed',
    GET_FAILED_APPLICATION_SYNC_DATA: '/admin/application/failed',
    DELETE_FAILED_APPLICATION: '/admin/application/failed',
    RESUBMIT_FAILED_APPLICATION: '/contract/re-submit',
    MICROSOFT_LOGIN: '/admin/microsoft-login'
};
