var constants = {
    ENV: {
        DEV: 'development',
        QA: 'qa',
        PRODUCTION: 'production'
    },
    FOOTER_TEXT: {
        TERMS: 'Terms of Use',
        PRIVACY: 'Privacy Policy'
    },
    regex: {
        emailRegExp: /^[a-zA-Z0-9._+]+@[a-zA-Z0-9._]+\.[a-zA-Z]{2,}$/,
        atleastOneUCharRegExp: /(?=.*?[A-Z])/,
        atleastOneLCharRegExp: /(?=.*?[a-z])/,
        atleastOneNumRegExp: /(?=.*?[0-9])/,
        atleastOneSplCharRegExp: /(?=.*?[!$%^&*])/,
        userNameRegExp: /^[A-Za-z\s]{1,50}$/,
        legalNameRegExp: /^[A-Za-z ]{1,50}$/,
        mobileNoRegExp: /^[0-9]{10}$/,
        urlRegex: /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
        spaceStrtsEndsWith: /^\s+|\s+$/g,
        dealerCode: /^[a-zA-Z0-9]{6,}$/,
        allNumbers: /^[1-9]\d*$/,
        numbersAndHypens: /^[0-9-]*$/,
        onlyNumeric: /^\d+$/,
        allCharsExceptSpaces: / /g,
        PhoneNumber_DownPayment: /\D/g,
        SSN_PostalCode: /[^a-zA-Z0-9]/g,
        OwnershipPercentage: /^[0-9]+$/,
    },
    MICROSOFT_CLIENT_ID: 'd2628c71-cd36-4f00-b526-5f863992b086',
    MICROSOFT_TENANT_ID: '4e615aa9-d517-4dec-9cbd-18d9f0d7a8df',
    MICROSOFT_CLINET_SECRET: 'gXx8Q~RdmkNa7YHA7BTGAAVZEwpx_k~Inlk26bxD',
    PortalTypes: [{ label: 'Dealer', value: 'DEALER' }, { label: 'Customer', value: 'CUSTOMER' }, { label: 'Banker', value: 'BANKER' }],
    requestStatus: {
        INITIATED: 'INITIATED',
        COMPLETED: 'COMPLETED',
        FAILED: 'FAILED'
    },
    US_DATE_FORMAT: 'MM/DD/YYYY',
    API_DATE_FORMAT: 'YYYY-MM-DD',
    TOAST_TYPES: {
        INFO: 'info',
        ERROR: 'error',
        WARN: 'warn',
    },
    NorAppObjJSON: {
        "Pricing": {
            "sections": {
                "customer preferences": [
                    {
                        "name": "Finance The Doc fees",
                        "type": "checkbox",
                        "fr_name": "Finances Les frais de Doc",
                        "required": false,
                        "hideField": false,
                        "apiFieldName": "IsDocFeeFinanced",
                        "defaultValue": true,
                        "disabled": false,
                        "commercial": {
                            "disabled": true,
                            "hideField": false,
                            "defaultValue": false,
                        }
                    }, {
                        "name": "Finance Soft Costs",
                        "type": "checkbox",
                        "fr_name": "Coûts accessoires du financement",
                        "required": false,
                        "hideField": false,
                        "apiFieldName": "IsSoftCostFinanced",
                        "defaultValue": true,
                        "disabled": false
                    },
                    {
                        "name": "Finance One-Time Tax, If Applicable",
                        "type": "checkbox",
                        "fr_name": "Financer une taxe unique, le cas échéant",
                        "required": false,
                        "hideField": true,
                        "apiFieldName": "IsOneTimeTaxFinanced",
                        "defaultValue": true,
                        "disabled": false,
                        "commercial": {
                            "disabled": true,
                            "hideField": false,
                            "defaultValue": false,
                        }
                    }
                ],
                "Pricing": [{
                        "name": "Finance Product",
                        "type": "dropdown",
                        "fr_name": "Produit financier",
                        "required": true,
                        "placeholder": "Select",
                        "apiFieldName": "FinanceProduct",
                        "fr_placeholder": "Sélectionner",
                        "dropdownOptions": []
                    }, {
                        "name": "Pricing Options",
                        "type": "dropdown",
                        "fr_name": "OPTIONS DE TARIF",
                        "required": true,
                        "placeholder": "Select",
                        "apiFieldName": "PricingOption",
                        "fr_placeholder": "Sélectionner",
                        "dropdownOptions": []
                    }, {
                        "name": "Term Length In Months",
                        "type": "dropdown",
                        "fr_name": "DURÉE DU MANDAT EN MOIS",
                        "required": true,
                        "placeholder": "Months",
                        "apiFieldName": "Term",
                        "fr_placeholder": "Mois",
                        "dropdownOptions": []
                    }, {
                        "name": "Down Payment",
                        "type": "text",
                        "fr_name": "ACOMPTE",
                        "required": true,
                        "placeholder": "0",
                        "apiFieldName": "DownPayment",
                        "fr_placeholder": "0"
                    }, {
                        "name": "Documentation Fee",
                        "type": "text",
                        "fr_name": "Frais de documentation",
                        "required": false,
                        "hideField": false,
                        "placeholder": "$0.00",
                        "apiFieldName": "DocFee",
                        "fr_placeholder": "$0.00"
                    }, {
                        "name": "Soft Costs",
                        "type": "text",
                        "fr_name": "COÛTS DOUX",
                        "required": false,
                        "hideField": false,
                        "placeholder": "$0.00",
                        "apiFieldName": "SoftCost",
                        "fr_placeholder": "$0.00"
                    }]
            }
        },
        "Files Upload": {},
        "Finance Options": {
            "sections": {
                "Finance Options": [
                    {
                        "name": "Finance Product",
                        "type": "dropdown",
                        "fr_name": "PRODUIT FINANCIER",
                        "required": true,
                        "placeholder": "Finance Product",
                        "apiFieldName": "FinanceProductId",
                        "fr_placeholder": "PRODUIT FINANCIER",
                        "dropdownOptions": [],
                        "dropdownOptionValueKey": "code",
                        "dropdownOptionDisplayKey": "label"
                    },
                    {
                        "name": "Term Length in Months",
                        "type": "dropdown",
                        "fr_name": "DURÉE DU MANDAT EN MOIS",
                        "required": true,
                        "placeholder": "Months",
                        "apiFieldName": "Term",
                        "fr_placeholder": "Mois",
                        "dropdownOptions": []
                    },
                    {
                        "name": "Down Payment",
                        "type": "text",
                        "fr_name": "ACOMPTE",
                        "required": true,
                        "placeholder": "Down Payment",
                        "apiFieldName": "DownPayment",
                        "fr_placeholder": "ACOMPTE"
                    },
                    {
                        "name": "Total Asset Cost",
                        "type": "text",
                        "fr_name": "COÛT TOTAL DE L'ACTIF",
                        "required": false,
                        "hideField": false,
                        "isEditable": false,
                        "placeholder": "Total Asset Cost",
                        "apiFieldName": "TotalAssetCost",
                        "fr_placeholder": "COÛT TOTAL DE L'ACTIF"
                    },
                    {
                        "name": "Total Amount Financed",
                        "type": "text",
                        "fr_name": "MONTANT TOTAL FINANCÉ",
                        "required": false,
                        "hideField": false,
                        "isEditable": false,
                        "placeholder": "Total Amount Financed",
                        "apiFieldName": "TotalAmountFinanced",
                        "fr_placeholder": "MONTANT TOTAL FINANCÉ"
                    },
                    {
                        "name": "Is the asset being used for Rental purposes?",
                        "type": "dropdown",
                        "fr_name": "L'actif est-il utilisé à des fins de location ?",
                        "required": true,
                        "toolTipMsg": "",
                        "placeholder": "Yes Or No",
                        "apiFieldName": "IsRental",
                        "fr_toolTipMsg": "",
                        "fr_placeholder": "Oui ou non",
                        "dropdownOptions": [
                            {
                                "label": "Yes",
                                "value": "Yes"
                            },
                            {
                                "label": "No",
                                "value": "No"
                            }
                        ]
                    }
                ]
            }
        },
        "Asset Information": {
            "sections": {
                "Asset Information": [
                    {
                        "name": "New Or Used",
                        "type": "dropdown",
                        "fr_name": "NEUF OU USAGÉ",
                        "required": true,
                        "placeholder": "New Or Used",
                        "apiFieldName": "IsNew",
                        "fr_placeholder": "NEUF OU USAGÉ",
                        "dropdownOptions": [
                            {
                                "label": "New",
                                "value": "New"
                            },
                            {
                                "label": "Used",
                                "value": "Used"
                            }
                        ]
                    },
                    {
                        "name": "Equipment Type",
                        "type": "dropdown",
                        "fr_name": "Type d'équipement",
                        "required": true,
                        "placeholder": "Equipment Type",
                        "apiFieldName": "EquipmentType",
                        "fr_placeholder": "Type d'équipement",
                        "dropdownOptions": []
                    },
                    {
                        "name": "Equipment Description",
                        "type": "text",
                        "fr_name": "DESCRIPTION DE L'ÉQUIPEMENT",
                        "required": true,
                        "placeholder": "Equipment Description",
                        "apiFieldName": "Description",
                        "fr_placeholder": "DESCRIPTION DE L'ÉQUIPEMENT",
                    },
                    {
                        "name": "Equipment Manufacturer",
                        "type": "dropdown",
                        "fr_name": "Fabricant d'équipement",
                        "required": true,
                        "placeholder": "Equipment Manufacturer",
                        "apiFieldName": "Manufacturer",
                        "fr_placeholder": "Fabricant d'équipement",
                        "dropdownOptions": []
                    },
                    {
                        "name": "Model",
                        "type": "dropdown",
                        "fr_name": "MODÈLE",
                        "required": false,
                        "hideField": false,
                        "placeholder": "Model",
                        "apiFieldName": "Model",
                        "fr_placeholder": "MODÈLE",
                        "dropdownOptions": []
                    },
                    {
                        "name": "Year",
                        "type": "text",
                        "fr_name": "ANNÉE",
                        "required": true,
                        "placeholder": "Year",
                        "apiFieldName": "Year",
                        "fr_placeholder": "ANNÉE"
                    },
                    {
                        "name": "SN/VIN#",
                        "type": "text",
                        "fr_name": "Numéro SN/VIN",
                        "required": false,
                        "hideField": false,
                        "toolTipMsg": "Please note this is an optional field. However, multiple SN / VIN # can be added for your assets, separated by commas, with a count matching the asset quantity. (e.g. SN12345,SN67890,VIN234567 for 3 assets)",
                        "placeholder": "SN/VIN#",
                        "apiFieldName": "SerialNums",
                        "fr_toolTipMsg": "Veuillez noter qu'il s'agit d'un champ facultatif. Cependant, plusieurs numéros SN/VIN peuvent être ajoutés pour vos actifs, séparés par des virgules, avec un nombre correspondant à la quantité d'actifs. (par exemple SN12345,SN67890,VIN234567 pour 3 actifs)",
                        "fr_placeholder": "Numéro SN/VIN"
                    },
                    {
                        "name": "Equipment Cost (Per Unit)",
                        "type": "text",
                        "fr_name": "COÛT DE L'ÉQUIPEMENT (PAR UNITÉ)",
                        "required": true,
                        "placeholder": "Cost",
                        "apiFieldName": "EquipmentCost",
                        "fr_placeholder": "COÛT"
                    },
                    {
                        "name": "Quantity",
                        "type": "text",
                        "fr_name": "QUANTITÉ",
                        "required": true,
                        "placeholder": "Quantity",
                        "apiFieldName": "Quantity",
                        "fr_placeholder": "QUANTITÉ"
                    }
                ]
            }
        },
        "Contact Information": {
            "sections": {
                "Primary Contact": [
                    {
                        "name": "First Name",
                        "type": "text",
                        "fr_name": "PRÉNOM",
                        "required": true,
                        "placeholder": "First Name",
                        "apiFieldName": "FirstName",
                        "fr_placeholder": "PRÉNOM"
                    },
                    {
                        "name": "Last Name",
                        "type": "text",
                        "fr_name": "NOM DE FAMILLE",
                        "required": true,
                        "placeholder": "Last Name",
                        "apiFieldName": "LastName",
                        "fr_placeholder": "NOM DE FAMILLE"
                    },
                    {
                        "name": "Suffix",
                        "type": "dropdown",
                        "fr_name": "Suffixe",
                        "required": false,
                        "placeholder": "Suffix",
                        "apiFieldName": "Suffix",
                        "fr_placeholder": "Suffixe",
                        "dropdownOptions": [
                            {
                                "label": "I",
                                "value": "I"
                            },
                            {
                                "label": "II",
                                "value": "II"
                            },
                            {
                                "label": "III",
                                "value": "III"
                            },
                            {
                                "label": "IV",
                                "value": "IV"
                            },
                            {
                                "label": "V",
                                "value": "V"
                            },
                            {
                                "label": "Sr.",
                                "value": "Sr."
                            },
                            {
                                "label": "Jr.",
                                "value": "Jr."
                            }
                        ]
                    },
                    {
                        "name": "Email",
                        "type": "text",
                        "fr_name": "E-MAIL",
                        "required": true,
                        "placeholder": "Email",
                        "apiFieldName": "Email",
                        "fr_placeholder": "E-MAIL"
                    },
                    {
                        "name": "Cell Phone Number",
                        "type": "text",
                        "fr_name": "NUMÉRO DE TÉLÉPHONE PORTABLE",
                        "required": true,
                        "placeholder": "Phone Number",
                        "apiFieldName": "PhoneNumber",
                        "fr_placeholder": "NUMÉRO DE TÉLÉPHONE PORTABLE",
                        "startAdornment": "+1"
                    },
                    {
                        "name": "Business Association",
                        "type": "dropdown",
                        "fr_name": "Association d'entreprises",
                        "required": true,
                        "placeholder": "Select",
                        "apiFieldName": "CustomerIndex",
                        "fr_placeholder": "Sélectionner",
                        "dropdownOptions": []
                    },
                    {
                        "name": "Title",
                        "type": "dropdown",
                        "fr_name": "Titre",
                        "required": false,
                        "placeholder": "Title",
                        "apiFieldName": "Title",
                        "fr_placeholder": "Titre",
                        "dropdownOptions": []
                    },
                    {
                        "name": "Doc Signer",
                        "type": "dropdown",
                        "fr_name": "SIGNATEUR DE DOCUMENT",
                        "required": true,
                        "toolTipMsg": "This is the person who is going to sign the documents",
                        "placeholder": "Doc Signer",
                        "apiFieldName": "DocSigner",
                        "fr_toolTipMsg": "C'est la personne qui va signer les documents",
                        "fr_placeholder": "SIGNATEUR DE DOCUMENT",
                        "dropdownOptions": [
                            {
                                "label": "Yes",
                                "value": "Yes"
                            },
                            {
                                "label": "No",
                                "value": "No"
                            }
                        ]
                    }
                ]
            }
        },
        "Customer Information": {
            "sections": {
                "Address Details": [
                    {
                        "name": "Main Address",
                        "type": "text",
                        "fr_name": "ADRESSE PRINCIPALE",
                        "required": true,
                        "maxLength": 100,
                        "toolTipMsg": "",
                        "placeholder": "Address",
                        "apiFieldName": "Address",
                        "fr_placeholder": "ADRESSE PRINCIPALE"
                    },
                    {
                        "name": "Country",
                        "type": "dropdown",
                        "fr_name": "PAYS",
                        "required": true,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "Country",
                        "apiFieldName": "Country",
                        "fr_placeholder": "PAYS",
                        "dropdownOptions": [],
                        "dropdownOptionValueKey": "code",
                        "dropdownOptionDisplayKey": "country"
                    },
                    {
                        "name": "City",
                        "type": "text",
                        "fr_name": "VILLE",
                        "required": true,
                        "maxLength": 100,
                        "toolTipMsg": "",
                        "placeholder": "City",
                        "apiFieldName": "City",
                        "fr_placeholder": "VILLE"
                    },
                    {
                        "name": "State/Province Of Incorporation",
                        "type": "dropdown",
                        "fr_name": "ÉTAT/PROVINCE DE CONSTITUTION",
                        "required": true,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "State",
                        "apiFieldName": "State",
                        "fr_placeholder": "ÉTAT/PROVINCE",
                        "dropdownOptions": [],
                        "dropdownOptionValueKey": "code",
                        "dropdownOptionDisplayKey": "state"
                    },
                    {
                        "name": "Zip/Postal Code",
                        "type": "text",
                        "fr_name": "Zip/Code postal",
                        "required": true,
                        "maxLength": 9,
                        "toolTipMsg": "",
                        "placeholder": "Zip/Postal Code",
                        "apiFieldName": "PostalCode",
                        "fr_placeholder": "ZIP/CODE POSTAL"
                    },
                    {
                        "name": "Email",
                        "type": "text",
                        "fr_name": "E-MAIL",
                        "required": true,
                        "maxLength": 60,
                        "toolTipMsg": "",
                        "placeholder": "Email",
                        "apiFieldName": "Email",
                        "fr_placeholder": "E-MAIL"
                    },
                    {
                        "name": "Phone Number",
                        "type": "text",
                        "fr_name": "NUMÉRO DE TÉLÉPHONE",
                        "required": true,
                        "maxLength": 10,
                        "toolTipMsg": "",
                        "placeholder": "Phone Number",
                        "apiFieldName": "PhoneNumber",
                        "fr_placeholder": "NUMÉRO DE TÉLÉPHONE",
                        "startAdornment": "+1"
                    }
                ],
                "Billing Address": [
                    {
                        "name": "Address",
                        "type": "text",
                        "fr_name": "ADRESSE PRINCIPALE",
                        "required": true,
                        "maxLength": 100,
                        "toolTipMsg": "",
                        "placeholder": "Address",
                        "apiFieldName": "BillingAddress",
                        "fr_placeholder": "ADRESSE PRINCIPALE"
                    },
                    {
                        "name": "Country",
                        "type": "dropdown",
                        "fr_name": "PAYS",
                        "required": true,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "Country",
                        "apiFieldName": "BillingCountry",
                        "fr_placeholder": "PAYS",
                        "dropdownOptions": [],
                        "dropdownOptionValueKey": "code",
                        "dropdownOptionDisplayKey": "country"
                    },
                    {
                        "name": "City",
                        "type": "text",
                        "fr_name": "VILLE",
                        "required": true,
                        "maxLength": 100,
                        "toolTipMsg": "",
                        "placeholder": "City",
                        "apiFieldName": "BillingCity",
                        "fr_placeholder": "VILLE"
                    },
                    {
                        "name": "State/Province Of Incorporation",
                        "type": "dropdown",
                        "fr_name": "ÉTAT/PROVINCE DE CONSTITUTION",
                        "required": true,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "State",
                        "apiFieldName": "BillingState",
                        "fr_placeholder": "STATE/PROVINCE",
                        "dropdownOptions": [],
                        "dropdownOptionValueKey": "code",
                        "dropdownOptionDisplayKey": "state"
                    },
                    {
                        "name": "Zip/Postal Code",
                        "type": "text",
                        "fr_name": "ZIP / CODE POSTAL",
                        "required": true,
                        "maxLength": 9,
                        "toolTipMsg": "",
                        "placeholder": "Zip/Postal Code",
                        "apiFieldName": "BillingPostalCode",
                        "fr_placeholder": "ZIP / CODE POSTAL"
                    },
                    {
                        "name": "Email",
                        "type": "text",
                        "fr_name": "E-MAIL",
                        "required": true,
                        "maxLength": 60,
                        "toolTipMsg": "",
                        "placeholder": "Email",
                        "apiFieldName": "BillingEmail",
                        "fr_placeholder": "E-MAIL"
                    },
                    {
                        "name": "Phone Number",
                        "type": "text",
                        "fr_name": "NUMÉRO DE TÉLÉPHONE",
                        "required": true,
                        "maxLength": 10,
                        "toolTipMsg": "",
                        "placeholder": "Phone Number",
                        "apiFieldName": "BillingPhoneNumber",
                        "fr_placeholder": "NUMÉRO DE TÉLÉPHONE",
                        "startAdornment": "+1"
                    }
                ],
                "Business Details": [
                    {
                        "name": "Legal Registered Name",
                        "type": "text",
                        "fr_name": "NOM LÉGAL ENREGISTRÉ",
                        "required": true,
                        "maxLength": 100,
                        "toolTipMsg": "",
                        "placeholder": "Legal Registered Name",
                        "apiFieldName": "LegalRegName",
                        "fr_placeholder": "NOM LÉGAL ENREGISTRÉ"
                    },
                    {
                        "name": "DBA Name",
                        "type": "text",
                        "fr_name": "NOM DBA",
                        "required": false,
                        "hideField": false,
                        "maxLength": 100,
                        "toolTipMsg": "”Doing Business As” or Tradename",
                        "placeholder": "DBA Name",
                        "apiFieldName": "DBAName",
                        "fr_toolTipMsg": "« Faire affaire sous le nom » ou nom commercial",
                        "fr_placeholder": "NOM DBA"
                    },
                    {
                        "name": "Entity Type",
                        "type": "dropdown",
                        "fr_name": "TYPE D'ENTITÉ",
                        "required": true,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "Entity Type",
                        "apiFieldName": "CompanyCode",
                        "fr_placeholder": "TYPE D'ENTITÉ",
                        "dropdownOptions": [
                            {
                                "id": "1",
                                "Code": "C",
                                "Description": "Corporation"
                            },
                            {
                                "id": '14',
                                "Code": 'SC',
                                "Description": 'S Corp'
                            },
                            {
                                "id": "3",
                                "Code": "G",
                                "Description": "Federal Government"
                            },
                            {
                                "id": "5",
                                "Code": "LLC",
                                "Description": "Limited Liability Company"
                            },
                            {
                                "id": "6",
                                "Code": "LLP",
                                "Description": "Limited Liability Partnership"
                            },
                            {
                                "id": "7",
                                "Code": "N",
                                "Description": "Non Profit"
                            },
                            {
                                "id": "8",
                                "Code": "O",
                                "Description": "Other"
                            },
                            {
                                "id": "9",
                                "Code": "P",
                                "Description": "Partnership"
                            },
                            {
                                "id": "10",
                                "Code": "R",
                                "Description": "Sole Proprietorship"
                            },
                            {
                                "id": "11",
                                "Code": "S",
                                "Description": "State/Local Government"
                            },
                            {
                                "id": "13",
                                "Code": "TRUST",
                                "Description": "Trust or Estate"
                            }
                        ],
                        "dropdownOptionValueKey": "Code",
                        "dropdownOptionDisplayKey": "Description"
                    },
                    {
                        "name": "Country",
                        "type": "dropdown",
                        "fr_name": "PAYS",
                        "required": true,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "Country",
                        "apiFieldName": "CustCountry",
                        "fr_placeholder": "PAYS",
                        "dropdownOptions": [],
                        "dropdownOptionValueKey": "code",
                        "dropdownOptionDisplayKey": "country"
                    },
                    {
                        "name": "State/Province Of Incorporation",
                        "type": "dropdown",
                        "fr_name": "ÉTAT/PROVINCE DE CONSTITUTION",
                        "required": true,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "State",
                        "apiFieldName": "CustState",
                        "fr_placeholder": "STATE/PROVINCE",
                        "dropdownOptions": [],
                        "dropdownOptionValueKey": "code",
                        "dropdownOptionDisplayKey": "state"
                    },
                    {
                        "name": "Date Business Incorporated",
                        "type": "date",
                        "fr_name": "DATE DE CONSTITUTION DE L'ENTREPRISE",
                        "required": false,
                        "hideField": false,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "MM/DD/YYYY",
                        "apiFieldName": "BusinessIncorporatedDate",
                        "fr_placeholder": "MM/DD/YYYY",
                        "dropdownOptions": []
                    },
                    {
                        "name": "FED TAX ID/BN#",
                        "type": "text",
                        "fr_name": "Numéro d'identification fiscale/BN de la FED",
                        "required": true,
                        "maxLength": 15,
                        "toolTipMsg": "Federal Tax ID (US)/Business Number (Canada)",
                        "placeholder": "FED TAX ID/BN#",
                        "apiFieldName": "SSN",
                        "fr_toolTipMsg": "Numéro d'identification fiscale fédérale (États-Unis)/numéro d'entreprise (Canada)",
                        "fr_placeholder": "Numéro d'identification fiscale/BN de la FED",
                        "dropdownOptions": []
                    },
                    {
                        "name": "Gross Annual Revenue",
                        "type": "text",
                        "fr_name": "Chiffre d'affaires annuel brut",
                        "hideField": true,
                        "required": false,
                        "placeholder": "Gross Annual Revenue",
                        "apiFieldName": "GrossRevenue",
                        "fr_placeholder": "Chiffre d'affaires annuel brut"
                    },
                    {
                        "name": "Tax Exempt",
                        "type": "dropdown",
                        "fr_name": "EXEMPT D'IMPÔT",
                        "required": true,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "",
                        "apiFieldName": "TaxExempt",
                        "fr_placeholder": "",
                        "dropdownOptions": [
                            {
                                "label": "Yes",
                                "value": "true"
                            },
                            {
                                "label": "No",
                                "value": "false"
                            }
                        ]
                    }
                ],
                "Individual Details": [
                    {
                        "name": "First Name",
                        "type": "text",
                        "fr_name": "Prénom",
                        "required": true,
                        "placeholder": "First Name",
                        "apiFieldName": "FirstName",
                        "fr_placeholder": "Prénom"
                    },
                    {
                        "name": "Middle Name",
                        "type": "text",
                        "fr_name": "Deuxième prénom",
                        "required": false,
                        "hideField": false,
                        "placeholder": "Middle Name",
                        "apiFieldName": "MiddleName",
                        "fr_placeholder": "Deuxième prénom"
                    },
                    {
                        "name": "Last Name",
                        "type": "text",
                        "fr_name": "Nom de famille",
                        "required": true,
                        "placeholder": "Last Name",
                        "apiFieldName": "LastName",
                        "fr_placeholder": "Nom de famille"
                    },
                    {
                        "name": "Suffix",
                        "type": "dropdown",
                        "fr_name": "Suffixe",
                        "required": false,
                        "placeholder": "Suffix",
                        "apiFieldName": "Suffix",
                        "fr_placeholder": "Suffixe",
                        "dropdownOptions": [
                            {
                                "label": "I",
                                "value": "I"
                            },
                            {
                                "label": "II",
                                "value": "II"
                            },
                            {
                                "label": "III",
                                "value": "III"
                            },
                            {
                                "label": "IV",
                                "value": "IV"
                            },
                            {
                                "label": "V",
                                "value": "V"
                            },
                            {
                                "label": "Sr.",
                                "value": "Sr."
                            },
                            {
                                "label": "Jr.",
                                "value": "Jr."
                            }
                        ]
                    },
                    {
                        "name": "Business Title",
                        "type": "text",
                        "fr_name": "Titre Professionnel",
                        "required": false,
                        "hideField": false,
                        "placeholder": "Business Title",
                        "apiFieldName": "BusinessTitle",
                        "fr_placeholder": "Titre Professionnel"
                    },
                    {
                        "name": "SSN#/TIN#/SIN#",
                        "type": "text",
                        "fr_name": "Numéro SSN/TIN#/SIN#",
                        "required": true,
                        "placeholder": "SSN Number",
                        "apiFieldName": "SSN",
                        "fr_placeholder": "Numéro SSN"
                    },
                    {
                        "name": "Date of Birth",
                        "type": "date",
                        "fr_name": "Date de naissance",
                        "required": true,
                        "placeholder": "Date of Birth",
                        "apiFieldName": "DOB",
                        "fr_placeholder": "Date de naissance"
                    }
                ]
            }
        },
        "Guarantor Information": {
            "sections": {
                "Home Address": [
                    {
                        "name": "Main Address",
                        "type": "text",
                        "fr_name": "Adresse principale",
                        "required": true,
                        "maxLength": 100,
                        "toolTipMsg": "",
                        "placeholder": "Address",
                        "apiFieldName": "Address",
                        "fr_placeholder": "Adresse"
                    },
                    {
                        "name": "Country",
                        "type": "dropdown",
                        "fr_name": "Pays",
                        "required": true,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "Country",
                        "apiFieldName": "Country",
                        "fr_placeholder": "Pays",
                        "dropdownOptions": [],
                        "dropdownOptionValueKey": "code",
                        "dropdownOptionDisplayKey": "country"
                    },
                    {
                        "name": "City",
                        "type": "text",
                        "fr_name": "Ville",
                        "required": true,
                        "maxLength": 100,
                        "toolTipMsg": "",
                        "placeholder": "City",
                        "apiFieldName": "City",
                        "fr_placeholder": "Ville"
                    },
                    {
                        "name": "State/Province Of Incorporation",
                        "type": "dropdown",
                        "fr_name": "État/Province",
                        "required": true,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "State",
                        "apiFieldName": "State",
                        "fr_placeholder": "État",
                        "dropdownOptions": [],
                        "dropdownOptionValueKey": "code",
                        "dropdownOptionDisplayKey": "state"
                    },
                    {
                        "name": "Zip/Postal Code",
                        "type": "text",
                        "fr_name": "Zip / code postal",
                        "required": true,
                        "maxLength": 9,
                        "toolTipMsg": "",
                        "placeholder": "Zip/Postal Code",
                        "apiFieldName": "PostalCode",
                        "fr_placeholder": "Code Postal"
                    },
                    {
                        "name": "Email",
                        "type": "text",
                        "fr_name": "E-mail",
                        "required": true,
                        "maxLength": 60,
                        "toolTipMsg": "",
                        "placeholder": "Email",
                        "apiFieldName": "Email",
                        "fr_placeholder": "E-mail"
                    },
                    {
                        "name": "Phone Number",
                        "type": "text",
                        "fr_name": "Numéro de téléphone portable",
                        "required": true,
                        "maxLength": 10,
                        "toolTipMsg": "",
                        "placeholder": "Phone Number",
                        "apiFieldName": "PhoneNumber",
                        "fr_placeholder": "Numéro de téléphone portable",
                        "startAdornment": "+1"
                    }
                ],
                "Business Details": [
                    {
                        "name": "Business Name",
                        "type": "text",
                        "fr_name": "nom de l'entreprise",
                        "required": true,
                        "placeholder": "Business Name",
                        "apiFieldName": "BusinessName",
                        "fr_placeholder": "nom de l'entreprise"
                    },
                    {
                        "name": "Company Type",
                        "type": "dropdown",
                        "fr_name": "TYPE D'ENTITÉ",
                        "required": true,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "Company Type",
                        "apiFieldName": "CompanyCode",
                        "fr_placeholder": "TYPE D'ENTITÉ",
                        "dropdownOptions": [
                            {
                                "id": "1",
                                "Code": "C",
                                "Description": "Corporation"
                            },
                            {
                                "id": "14",
                                "Code": "SC",
                                "Description": "S Corp"
                            },
                            {
                                "id": "3",
                                "Code": "G",
                                "Description": "Federal Government"
                            },
                            {
                                "id": "5",
                                "Code": "LLC",
                                "Description": "Limited Liability Company"
                            },
                            {
                                "id": "6",
                                "Code": "LLP",
                                "Description": "Limited Liability Partnership"
                            },
                            {
                                "id": "7",
                                "Code": "N",
                                "Description": "Non Profit"
                            },
                            {
                                "id": "8",
                                "Code": "O",
                                "Description": "Other"
                            },
                            {
                                "id": "9",
                                "Code": "P",
                                "Description": "Partnership"
                            },
                            {
                                "id": "10",
                                "Code": "R",
                                "Description": "Sole Proprietorship"
                            },
                            {
                                "id": "11",
                                "Code": "S",
                                "Description": "State/Local Government"
                            },
                            {
                                "id": "13",
                                "Code": "TRUST",
                                "Description": "Trust or Estate"
                            }
                        ],
                        "dropdownOptionValueKey": "Code",
                        "dropdownOptionDisplayKey": "Description"
                    },
                    {
                        "name": "Percent Ownership%",
                        "type": "text",
                        "fr_name": "pourcentage de propriété%",
                        "required": true,
                        "placeholder": "Percent Ownership",
                        "apiFieldName": "OwnershipPercentage",
                        "fr_placeholder": "Pourcentage de propriété"
                    },
                    {
                        "name": "FED TAX ID/BN#",
                        "type": "text",
                        "fr_name": "Numéro d'identification fiscale/BN de la FED",
                        "required": true,
                        "placeholder": "Tax ID",
                        "apiFieldName": "SSN",
                        "fr_placeholder": "Numéro d'identification fiscale"
                    }
                ],
                "Individual Details": [
                    {
                        "name": "First Name",
                        "type": "text",
                        "fr_name": "Prénom",
                        "required": true,
                        "placeholder": "First Name",
                        "apiFieldName": "FirstName",
                        "fr_placeholder": "Prénom"
                    },
                    {
                        "name": "Middle Name",
                        "type": "text",
                        "fr_name": "Deuxième prénom",
                        "required": false,
                        "hideField": false,
                        "placeholder": "Middle Name",
                        "apiFieldName": "MiddleName",
                        "fr_placeholder": "Deuxième prénom"
                    },
                    {
                        "name": "Last Name",
                        "type": "text",
                        "fr_name": "Nom de famille",
                        "required": true,
                        "placeholder": "Last Name",
                        "apiFieldName": "LastName",
                        "fr_placeholder": "Nom de famille"
                    },
                    {
                        "name": "Suffix",
                        "type": "dropdown",
                        "fr_name": "Suffixe",
                        "required": false,
                        "placeholder": "Suffix",
                        "apiFieldName": "Suffix",
                        "fr_placeholder": "Suffixe",
                        "dropdownOptions": [
                            {
                                "label": "I",
                                "value": "I"
                            },
                            {
                                "label": "II",
                                "value": "II"
                            },
                            {
                                "label": "III",
                                "value": "III"
                            },
                            {
                                "label": "IV",
                                "value": "IV"
                            },
                            {
                                "label": "V",
                                "value": "V"
                            },
                            {
                                "label": "Sr.",
                                "value": "Sr."
                            },
                            {
                                "label": "Jr.",
                                "value": "Jr."
                            }
                        ]
                    },
                    {
                        "name": "Percent Ownership%",
                        "type": "text",
                        "fr_name": "pourcentage de propriété%",
                        "required": true,
                        "placeholder": "Percent Ownership",
                        "apiFieldName": "OwnershipPercentage",
                        "fr_placeholder": "Pourcentage de propriété"
                    },
                    {
                        "name": "Business Title",
                        "type": "text",
                        "fr_name": "Titre Professionnel",
                        "required": false,
                        "hideField": false,
                        "placeholder": "Business Title",
                        "apiFieldName": "BusinessTitle",
                        "fr_placeholder": "Titre Professionnel"
                    },
                    {
                        "name": "SSN#/TIN#/SIN#",
                        "type": "text",
                        "fr_name": "Numéro SSN/TIN#/SIN#",
                        "required": true,
                        "placeholder": "SSN Number",
                        "apiFieldName": "SSN",
                        "fr_placeholder": "Numéro SSN"
                    },
                    {
                        "name": "Date of Birth",
                        "type": "date",
                        "fr_name": "Date de naissance",
                        "required": true,
                        "placeholder": "Date of Birth",
                        "apiFieldName": "DOB",
                        "fr_placeholder": "Date de naissance"
                    }
                ]
            }
        }
    },
    LocAppObjJSON: {
        "Files Upload": {},
        "Contact Information": {
            "sections": {
                "Primary Contact": [
                    {
                        "name": "First Name",
                        "type": "text",
                        "fr_name": "PRÉNOM",
                        "required": true,
                        "placeholder": "First Name",
                        "apiFieldName": "FirstName",
                        "fr_placeholder": "PRÉNOM"
                    },
                    {
                        "name": "Last Name",
                        "type": "text",
                        "fr_name": "NOM DE FAMILLE",
                        "required": true,
                        "placeholder": "Last Name",
                        "apiFieldName": "LastName",
                        "fr_placeholder": "NOM DE FAMILLE"
                    },
                    {
                        "name": "Suffix",
                        "type": "dropdown",
                        "fr_name": "Suffixe",
                        "required": false,
                        "placeholder": "Suffix",
                        "apiFieldName": "Suffix",
                        "fr_placeholder": "Suffixe",
                        "dropdownOptions": [
                            {
                                "label": "I",
                                "value": "I"
                            },
                            {
                                "label": "II",
                                "value": "II"
                            },
                            {
                                "label": "III",
                                "value": "III"
                            },
                            {
                                "label": "IV",
                                "value": "IV"
                            },
                            {
                                "label": "V",
                                "value": "V"
                            },
                            {
                                "label": "Sr.",
                                "value": "Sr."
                            },
                            {
                                "label": "Jr.",
                                "value": "Jr."
                            }
                        ]
                    },
                    {
                        "name": "Email",
                        "type": "text",
                        "fr_name": "E-MAIL",
                        "required": true,
                        "placeholder": "Email",
                        "apiFieldName": "Email",
                        "fr_placeholder": "E-MAIL"
                    },
                    {
                        "name": "Cell Phone Number",
                        "type": "text",
                        "fr_name": "NUMÉRO DE TÉLÉPHONE PORTABLE",
                        "required": true,
                        "placeholder": "Phone Number",
                        "apiFieldName": "PhoneNumber",
                        "fr_placeholder": "NUMÉRO DE TÉLÉPHONE PORTABLE",
                        "startAdornment": "+1"
                    },
                    {
                        "name": "Business Association",
                        "type": "dropdown",
                        "fr_name": "Association d'entreprises",
                        "required": true,
                        "placeholder": "Select",
                        "apiFieldName": "CustomerIndex",
                        "fr_placeholder": "Sélectionner",
                        "dropdownOptions": []
                    },
                    {
                        "name": "Title",
                        "type": "dropdown",
                        "fr_name": "Titre",
                        "required": false,
                        "placeholder": "Title",
                        "apiFieldName": "Title",
                        "fr_placeholder": "Titre",
                        "dropdownOptions": []
                    },
                    {
                        "name": "Doc Signer",
                        "type": "dropdown",
                        "fr_name": "SIGNATEUR DE DOCUMENT",
                        "required": true,
                        "toolTipMsg": "This is the person who is going to sign the documents",
                        "placeholder": "Doc Signer",
                        "apiFieldName": "DocSigner",
                        "fr_toolTipMsg": "C'est la personne qui va signer les documents",
                        "fr_placeholder": "SIGNATEUR DE DOCUMENT",
                        "dropdownOptions": [
                            {
                                "label": "Yes",
                                "value": "Yes"
                            },
                            {
                                "label": "No",
                                "value": "No"
                            }
                        ]
                    }
                ]
            }
        },
        "Customer Information": {
            "sections": {
                "Address Details": [
                    {
                        "name": "Main Address",
                        "type": "text",
                        "fr_name": "ADRESSE PRINCIPALE",
                        "required": true,
                        "maxLength": 100,
                        "toolTipMsg": "",
                        "placeholder": "Address",
                        "apiFieldName": "Address",
                        "fr_placeholder": "ADRESSE PRINCIPALE"
                    },
                    {
                        "name": "Country",
                        "type": "dropdown",
                        "fr_name": "PAYS",
                        "required": true,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "Country",
                        "apiFieldName": "Country",
                        "fr_placeholder": "PAYS",
                        "dropdownOptions": [],
                        "dropdownOptionValueKey": "code",
                        "dropdownOptionDisplayKey": "country"
                    },
                    {
                        "name": "City",
                        "type": "text",
                        "fr_name": "VILLE",
                        "required": true,
                        "maxLength": 100,
                        "toolTipMsg": "",
                        "placeholder": "City",
                        "apiFieldName": "City",
                        "fr_placeholder": "VILLE"
                    },
                    {
                        "name": "State/Province Of Incorporation",
                        "type": "dropdown",
                        "fr_name": "ÉTAT/PROVINCE DE CONSTITUTION",
                        "required": true,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "State",
                        "apiFieldName": "State",
                        "fr_placeholder": "ÉTAT/PROVINCE",
                        "dropdownOptions": [],
                        "dropdownOptionValueKey": "code",
                        "dropdownOptionDisplayKey": "state"
                    },
                    {
                        "name": "Zip/Postal Code",
                        "type": "text",
                        "fr_name": "Zip/Code postal",
                        "required": true,
                        "maxLength": 9,
                        "toolTipMsg": "",
                        "placeholder": "Zip/Postal Code",
                        "apiFieldName": "PostalCode",
                        "fr_placeholder": "ZIP/CODE POSTAL"
                    },
                    {
                        "name": "Email",
                        "type": "text",
                        "fr_name": "E-MAIL",
                        "required": true,
                        "maxLength": 60,
                        "toolTipMsg": "",
                        "placeholder": "Email",
                        "apiFieldName": "Email",
                        "fr_placeholder": "E-MAIL"
                    },
                    {
                        "name": "Phone Number",
                        "type": "text",
                        "fr_name": "NUMÉRO DE TÉLÉPHONE",
                        "required": true,
                        "maxLength": 10,
                        "toolTipMsg": "",
                        "placeholder": "Phone Number",
                        "apiFieldName": "PhoneNumber",
                        "fr_placeholder": "NUMÉRO DE TÉLÉPHONE",
                        "startAdornment": "+1"
                    }
                ],
                "Billing Address": [
                    {
                        "name": "Address",
                        "type": "text",
                        "fr_name": "ADRESSE PRINCIPALE",
                        "required": true,
                        "maxLength": 100,
                        "toolTipMsg": "",
                        "placeholder": "Address",
                        "apiFieldName": "BillingAddress",
                        "fr_placeholder": "ADRESSE PRINCIPALE"
                    },
                    {
                        "name": "Country",
                        "type": "dropdown",
                        "fr_name": "PAYS",
                        "required": true,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "Country",
                        "apiFieldName": "BillingCountry",
                        "fr_placeholder": "PAYS",
                        "dropdownOptions": [],
                        "dropdownOptionValueKey": "code",
                        "dropdownOptionDisplayKey": "country"
                    },
                    {
                        "name": "City",
                        "type": "text",
                        "fr_name": "VILLE",
                        "required": true,
                        "maxLength": 100,
                        "toolTipMsg": "",
                        "placeholder": "City",
                        "apiFieldName": "BillingCity",
                        "fr_placeholder": "VILLE"
                    },
                    {
                        "name": "State/Province Of Incorporation",
                        "type": "dropdown",
                        "fr_name": "ÉTAT/PROVINCE DE CONSTITUTION",
                        "required": true,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "State",
                        "apiFieldName": "BillingState",
                        "fr_placeholder": "STATE/PROVINCE",
                        "dropdownOptions": [],
                        "dropdownOptionValueKey": "code",
                        "dropdownOptionDisplayKey": "state"
                    },
                    {
                        "name": "Zip/Postal Code",
                        "type": "text",
                        "fr_name": "ZIP / CODE POSTAL",
                        "required": true,
                        "maxLength": 9,
                        "toolTipMsg": "",
                        "placeholder": "Zip/Postal Code",
                        "apiFieldName": "BillingPostalCode",
                        "fr_placeholder": "ZIP / CODE POSTAL"
                    },
                    {
                        "name": "Email",
                        "type": "text",
                        "fr_name": "E-MAIL",
                        "required": true,
                        "maxLength": 60,
                        "toolTipMsg": "",
                        "placeholder": "Email",
                        "apiFieldName": "BillingEmail",
                        "fr_placeholder": "E-MAIL"
                    },
                    {
                        "name": "Phone Number",
                        "type": "text",
                        "fr_name": "NUMÉRO DE TÉLÉPHONE",
                        "required": true,
                        "maxLength": 10,
                        "toolTipMsg": "",
                        "placeholder": "Phone Number",
                        "apiFieldName": "BillingPhoneNumber",
                        "fr_placeholder": "NUMÉRO DE TÉLÉPHONE",
                        "startAdornment": "+1"
                    }
                ],
                "Business Details": [
                    {
                        "name": "Legal Registered Name",
                        "type": "text",
                        "fr_name": "NOM LÉGAL ENREGISTRÉ",
                        "required": true,
                        "maxLength": 100,
                        "toolTipMsg": "",
                        "placeholder": "Legal Registered Name",
                        "apiFieldName": "LegalRegName",
                        "fr_placeholder": "NOM LÉGAL ENREGISTRÉ"
                    },
                    {
                        "name": "DBA Name",
                        "type": "text",
                        "fr_name": "NOM DBA",
                        "required": false,
                        "hideField": false,
                        "maxLength": 100,
                        "toolTipMsg": "”Doing Business As” or Tradename",
                        "placeholder": "DBA Name",
                        "apiFieldName": "DBAName",
                        "fr_toolTipMsg": "« Faire affaire sous le nom » ou nom commercial",
                        "fr_placeholder": "NOM DBA"
                    },
                    {
                        "name": "Entity Type",
                        "type": "dropdown",
                        "fr_name": "TYPE D'ENTITÉ",
                        "required": true,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "Entity Type",
                        "apiFieldName": "CompanyCode",
                        "fr_placeholder": "TYPE D'ENTITÉ",
                        "dropdownOptions": [
                            {
                                "id": "1",
                                "Code": "C",
                                "Description": "Corporation"
                            },
                            {
                                "id": "14",
                                "Code": "SC",
                                "Description": "S Corp"
                            },
                            {
                                "id": "3",
                                "Code": "G",
                                "Description": "Federal Government"
                            },
                            {
                                "id": "5",
                                "Code": "LLC",
                                "Description": "Limited Liability Company"
                            },
                            {
                                "id": "6",
                                "Code": "LLP",
                                "Description": "Limited Liability Partnership"
                            },
                            {
                                "id": "7",
                                "Code": "N",
                                "Description": "Non Profit"
                            },
                            {
                                "id": "8",
                                "Code": "O",
                                "Description": "Other"
                            },
                            {
                                "id": "9",
                                "Code": "P",
                                "Description": "Partnership"
                            },
                            {
                                "id": "10",
                                "Code": "R",
                                "Description": "Sole Proprietorship"
                            },
                            {
                                "id": "11",
                                "Code": "S",
                                "Description": "State/Local Government"
                            },
                            {
                                "id": "13",
                                "Code": "TRUST",
                                "Description": "Trust or Estate"
                            }
                        ],
                        "dropdownOptionValueKey": "Code",
                        "dropdownOptionDisplayKey": "Description"
                    },
                    {
                        "name": "Country",
                        "type": "dropdown",
                        "fr_name": "PAYS",
                        "required": true,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "Country",
                        "apiFieldName": "CustCountry",
                        "fr_placeholder": "PAYS",
                        "dropdownOptions": [],
                        "dropdownOptionValueKey": "code",
                        "dropdownOptionDisplayKey": "country"
                    },
                    {
                        "name": "State/Province Of Incorporation",
                        "type": "dropdown",
                        "fr_name": "ÉTAT/PROVINCE DE CONSTITUTION",
                        "required": true,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "State",
                        "apiFieldName": "CustState",
                        "fr_placeholder": "STATE/PROVINCE",
                        "dropdownOptions": [],
                        "dropdownOptionValueKey": "code",
                        "dropdownOptionDisplayKey": "state"
                    },
                    {
                        "name": "Date Business Incorporated",
                        "type": "date",
                        "fr_name": "DATE DE CONSTITUTION DE L'ENTREPRISE",
                        "required": false,
                        "hideField": false,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "MM/DD/YYYY",
                        "apiFieldName": "BusinessIncorporatedDate",
                        "fr_placeholder": "MM/DD/YYYY",
                        "dropdownOptions": []
                    },
                    {
                        "name": "FED TAX ID/BN#",
                        "type": "text",
                        "fr_name": "Numéro d'identification fiscale/BN de la FED",
                        "required": true,
                        "maxLength": 15,
                        "toolTipMsg": "Federal Tax ID (US)/Business Number (Canada)",
                        "placeholder": "FED TAX ID/BN#",
                        "apiFieldName": "SSN",
                        "fr_toolTipMsg": "Numéro d'identification fiscale fédérale (États-Unis)/numéro d'entreprise (Canada)",
                        "fr_placeholder": "Numéro d'identification fiscale/BN de la FED",
                        "dropdownOptions": []
                    },
                    {
                        "name": "Gross Annual Revenue",
                        "type": "text",
                        "fr_name": "Chiffre d'affaires annuel brut",
                        "hideField": true,
                        "required": false,
                        "placeholder": "Gross Annual Revenue",
                        "apiFieldName": "GrossRevenue",
                        "fr_placeholder": "Chiffre d'affaires annuel brut"
                    },
                    {
                        "name": "Tax Exempt",
                        "type": "dropdown",
                        "fr_name": "EXEMPT D'IMPÔT",
                        "required": true,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "",
                        "apiFieldName": "TaxExempt",
                        "fr_placeholder": "",
                        "dropdownOptions": [
                            {
                                "label": "Yes",
                                "value": "true"
                            },
                            {
                                "label": "No",
                                "value": "false"
                            }
                        ]
                    }
                ],
                "Individual Details": [
                    {
                        "name": "First Name",
                        "type": "text",
                        "fr_name": "Prénom",
                        "required": true,
                        "placeholder": "First Name",
                        "apiFieldName": "FirstName",
                        "fr_placeholder": "Prénom"
                    },
                    {
                        "name": "Middle Name",
                        "type": "text",
                        "fr_name": "Deuxième prénom",
                        "required": false,
                        "hideField": false,
                        "placeholder": "Middle Name",
                        "apiFieldName": "MiddleName",
                        "fr_placeholder": "Deuxième prénom"
                    },
                    {
                        "name": "Last Name",
                        "type": "text",
                        "fr_name": "Nom de famille",
                        "required": true,
                        "placeholder": "Last Name",
                        "apiFieldName": "LastName",
                        "fr_placeholder": "Nom de famille"
                    },
                    {
                        "name": "Suffix",
                        "type": "dropdown",
                        "fr_name": "Suffixe",
                        "required": false,
                        "placeholder": "Suffix",
                        "apiFieldName": "Suffix",
                        "fr_placeholder": "Suffixe",
                        "dropdownOptions": [
                            {
                                "label": "I",
                                "value": "I"
                            },
                            {
                                "label": "II",
                                "value": "II"
                            },
                            {
                                "label": "III",
                                "value": "III"
                            },
                            {
                                "label": "IV",
                                "value": "IV"
                            },
                            {
                                "label": "V",
                                "value": "V"
                            },
                            {
                                "label": "Sr.",
                                "value": "Sr."
                            },
                            {
                                "label": "Jr.",
                                "value": "Jr."
                            }
                        ]
                    },
                    {
                        "name": "Business Title",
                        "type": "text",
                        "fr_name": "Titre Professionnel",
                        "required": false,
                        "hideField": false,
                        "placeholder": "Business Title",
                        "apiFieldName": "BusinessTitle",
                        "fr_placeholder": "Titre Professionnel"
                    },
                    {
                        "name": "SSN#/TIN#/SIN#",
                        "type": "text",
                        "fr_name": "Numéro SSN/TIN#/SIN#",
                        "required": true,
                        "placeholder": "SSN Number",
                        "apiFieldName": "SSN",
                        "fr_placeholder": "Numéro SSN"
                    },
                    {
                        "name": "Date of Birth",
                        "type": "date",
                        "fr_name": "Date de naissance",
                        "required": true,
                        "placeholder": "Date of Birth",
                        "apiFieldName": "DOB",
                        "fr_placeholder": "Date de naissance"
                    }
                ]
            }
        },
        "Guarantor Information": {
            "sections": {
                "Home Address": [
                    {
                        "name": "Main Address",
                        "type": "text",
                        "fr_name": "Adresse principale",
                        "required": true,
                        "maxLength": 100,
                        "toolTipMsg": "",
                        "placeholder": "Address",
                        "apiFieldName": "Address",
                        "fr_placeholder": "Adresse"
                    },
                    {
                        "name": "Country",
                        "type": "dropdown",
                        "fr_name": "Pays",
                        "required": true,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "Country",
                        "apiFieldName": "Country",
                        "fr_placeholder": "Pays",
                        "dropdownOptions": [],
                        "dropdownOptionValueKey": "code",
                        "dropdownOptionDisplayKey": "country"
                    },
                    {
                        "name": "City",
                        "type": "text",
                        "fr_name": "Ville",
                        "required": true,
                        "maxLength": 100,
                        "toolTipMsg": "",
                        "placeholder": "City",
                        "apiFieldName": "City",
                        "fr_placeholder": "Ville"
                    },
                    {
                        "name": "State/Province Of Incorporation",
                        "type": "dropdown",
                        "fr_name": "État/Province",
                        "required": true,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "State",
                        "apiFieldName": "State",
                        "fr_placeholder": "État",
                        "dropdownOptions": [],
                        "dropdownOptionValueKey": "code",
                        "dropdownOptionDisplayKey": "state"
                    },
                    {
                        "name": "Zip/Postal Code",
                        "type": "text",
                        "fr_name": "Zip / code postal",
                        "required": true,
                        "maxLength": 9,
                        "toolTipMsg": "",
                        "placeholder": "Zip/Postal Code",
                        "apiFieldName": "PostalCode",
                        "fr_placeholder": "Code Postal"
                    },
                    {
                        "name": "Email",
                        "type": "text",
                        "fr_name": "E-mail",
                        "required": true,
                        "maxLength": 60,
                        "toolTipMsg": "",
                        "placeholder": "Email",
                        "apiFieldName": "Email",
                        "fr_placeholder": "E-mail"
                    },
                    {
                        "name": "Phone Number",
                        "type": "text",
                        "fr_name": "Numéro de téléphone portable",
                        "required": true,
                        "maxLength": 10,
                        "toolTipMsg": "",
                        "placeholder": "Phone Number",
                        "apiFieldName": "PhoneNumber",
                        "fr_placeholder": "Numéro de téléphone portable",
                        "startAdornment": "+1"
                    }
                ],
                "Business Details": [
                    {
                        "name": "Business Name",
                        "type": "text",
                        "fr_name": "nom de l'entreprise",
                        "required": true,
                        "placeholder": "Business Name",
                        "apiFieldName": "BusinessName",
                        "fr_placeholder": "nom de l'entreprise"
                    },
                    {
                        "name": "Company Type",
                        "type": "dropdown",
                        "fr_name": "TYPE D'ENTITÉ",
                        "required": true,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "Company Type",
                        "apiFieldName": "CompanyCode",
                        "fr_placeholder": "TYPE D'ENTITÉ",
                        "dropdownOptions": [
                            {
                                "id": "1",
                                "Code": "C",
                                "Description": "Corporation"
                            },
                            {
                                "id": "14",
                                "Code": "SC",
                                "Description": "S Corp"
                            },
                            {
                                "id": "3",
                                "Code": "G",
                                "Description": "Federal Government"
                            },
                            {
                                "id": "5",
                                "Code": "LLC",
                                "Description": "Limited Liability Company"
                            },
                            {
                                "id": "6",
                                "Code": "LLP",
                                "Description": "Limited Liability Partnership"
                            },
                            {
                                "id": "7",
                                "Code": "N",
                                "Description": "Non Profit"
                            },
                            {
                                "id": "8",
                                "Code": "O",
                                "Description": "Other"
                            },
                            {
                                "id": "9",
                                "Code": "P",
                                "Description": "Partnership"
                            },
                            {
                                "id": "10",
                                "Code": "R",
                                "Description": "Sole Proprietorship"
                            },
                            {
                                "id": "11",
                                "Code": "S",
                                "Description": "State/Local Government"
                            },
                            {
                                "id": "13",
                                "Code": "TRUST",
                                "Description": "Trust or Estate"
                            }
                        ],
                        "dropdownOptionValueKey": "Code",
                        "dropdownOptionDisplayKey": "Description"
                    },
                    {
                        "name": "Percent Ownership%",
                        "type": "text",
                        "fr_name": "pourcentage de propriété%",
                        "required": true,
                        "placeholder": "Percent Ownership",
                        "apiFieldName": "OwnershipPercentage",
                        "fr_placeholder": "Pourcentage de propriété"
                    },
                    {
                        "name": "FED TAX ID/BN#",
                        "type": "text",
                        "fr_name": "Numéro d'identification fiscale/BN de la FED",
                        "required": true,
                        "placeholder": "Tax ID",
                        "apiFieldName": "SSN",
                        "fr_placeholder": "Numéro d'identification fiscale"
                    }
                ],
                "Individual Details": [
                    {
                        "name": "First Name",
                        "type": "text",
                        "fr_name": "Prénom",
                        "required": true,
                        "placeholder": "First Name",
                        "apiFieldName": "FirstName",
                        "fr_palceholder": "Prénom"
                    },
                    {
                        "name": "Middle Name",
                        "type": "text",
                        "fr_name": "Deuxième prénom",
                        "required": false,
                        "hideField": false,
                        "placeholder": "Middle Name",
                        "apiFieldName": "MiddleName",
                        "fr_placeholder": "Deuxième prénom"
                    },
                    {
                        "name": "Last Name",
                        "type": "text",
                        "fr_name": "Nom de famille",
                        "required": true,
                        "placeholder": "Last Name",
                        "apiFieldName": "LastName",
                        "fr_placeholder": "Nom de famille"
                    },
                    {
                        "name": "Suffix",
                        "type": "dropdown",
                        "fr_name": "Suffixe",
                        "required": false,
                        "placeholder": "Suffix",
                        "apiFieldName": "Suffix",
                        "fr_placeholder": "Suffixe",
                        "dropdownOptions": [
                            {
                                "label": "I",
                                "value": "I"
                            },
                            {
                                "label": "II",
                                "value": "II"
                            },
                            {
                                "label": "III",
                                "value": "III"
                            },
                            {
                                "label": "IV",
                                "value": "IV"
                            },
                            {
                                "label": "V",
                                "value": "V"
                            },
                            {
                                "label": "Sr.",
                                "value": "Sr."
                            },
                            {
                                "label": "Jr.",
                                "value": "Jr."
                            }
                        ]
                    },
                    {
                        "name": "Percent Ownership%",
                        "type": "text",
                        "fr_name": "pourcentage de propriété%",
                        "required": true,
                        "placeholder": "Percent Ownership",
                        "apiFieldName": "OwnershipPercentage",
                        "fr_placeholder": "Pourcentage de propriété"
                    },
                    {
                        "name": "Business Title",
                        "type": "text",
                        "fr_name": "Titre Professionnel",
                        "required": false,
                        "hideField": false,
                        "placeholder": "Business Title",
                        "apiFieldName": "BusinessTitle",
                        "fr_placeholder": "Titre Professionnel"
                    },
                    {
                        "name": "SSN#/TIN#/SIN#",
                        "type": "text",
                        "fr_name": "Numéro SSN/TIN#/SIN#",
                        "required": true,
                        "placeholder": "SSN Number",
                        "apiFieldName": "SSN",
                        "fr_placeholder": "Numéro SSN"
                    },
                    {
                        "name": "Date of Birth",
                        "type": "date",
                        "fr_name": "Date de naissance",
                        "required": true,
                        "placeholder": "Date of Birth",
                        "apiFieldName": "DOB",
                        "fr_placeholder": "Date de naissance"
                    }
                ]
            }
        },
        "Line of Credit Request": {
            "sections": {
                "Line of Credit Request": [
                    {
                        "name": "Amount Requested",
                        "type": "text",
                        "fr_name": "Montant demandé",
                        "required": true,
                        "placeholder": "$0.00",
                        "apiFieldName": "AmountRequested",
                        "fr_placeholder": "$0.00",
                        "dropdownOptions": []
                    },
                    {
                        "name": "Purpose of Finance",
                        "type": "text",
                        "fr_name": "Objectif du financement",
                        "required": true,
                        "placeholder": "Enter Text",
                        "apiFieldName": "PurposeOfFinance",
                        "fr_placeholder": "Entrez du texte",
                        "dropdownOptions": []
                    }
                ]
            }
        }
    },
    SchAppObjJSON: {
        "Pricing": {
            "sections": {
                "customer preferences": [
                    {
                        "name": "Finance The Doc fees",
                        "type": "checkbox",
                        "fr_name": "Finances Les frais de Doc",
                        "required": false,
                        "hideField": false,
                        "defaultValue": true,
                        "disabled": false,
                        "apiFieldName": "IsDocFeeFinanced",
                        "commercial": {
                            "disabled": true,
                            "hideField": false,
                            "defaultValue": false,
                        }
                    }, {
                        "name": "Finance Soft Costs",
                        "type": "checkbox",
                        "fr_name": "Coûts accessoires du financement",
                        "required": false,
                        "hideField": false,
                        "defaultValue": true,
                        "disabled": false,
                        "apiFieldName": "IsSoftCostFinanced"
                    },
                    {
                        "name": "Finance One-Time Tax, If Applicable",
                        "type": "checkbox",
                        "fr_name": "Financer une taxe unique, le cas échéant",
                        "required": false,
                        "hideField": true,
                        "defaultValue": true,
                        "disabled": false,
                        "apiFieldName": "IsOneTimeTaxFinanced",
                        "commercial": {
                            "disabled": true,
                            "hideField": false,
                            "defaultValue": false,
                        }
                    }
                ],
                "Pricing": [{
                        "name": "Finance Product",
                        "type": "dropdown",
                        "fr_name": "Produit financier",
                        "required": true,
                        "placeholder": "Select",
                        "apiFieldName": "FinanceProduct",
                        "fr_placeholder": "Sélectionner",
                        "dropdownOptions": []
                    }, {
                        "name": "Pricing Options",
                        "type": "dropdown",
                        "fr_name": "OPTIONS DE TARIF",
                        "required": true,
                        "placeholder": "Select",
                        "apiFieldName": "PricingOption",
                        "fr_placeholder": "Sélectionner",
                        "dropdownOptions": []
                    }, {
                        "name": "Term Length In Months",
                        "type": "dropdown",
                        "fr_name": "DURÉE DU MANDAT EN MOIS",
                        "required": true,
                        "placeholder": "Months",
                        "apiFieldName": "Term",
                        "fr_placeholder": "Mois",
                        "dropdownOptions": []
                    }, {
                        "name": "Down Payment",
                        "type": "text",
                        "fr_name": "ACOMPTE",
                        "required": true,
                        "placeholder": "0",
                        "apiFieldName": "DownPayment",
                        "fr_placeholder": "0"
                    }, {
                        "name": "Documentation Fee",
                        "type": "text",
                        "fr_name": "Frais de documentation",
                        "required": false,
                        "hideField": false,
                        "placeholder": "$0.00",
                        "apiFieldName": "DocFee",
                        "fr_placeholder": "$0.00"
                    }, {
                        "name": "Soft Costs",
                        "type": "text",
                        "fr_name": "COÛTS DOUX",
                        "required": false,
                        "hideField": false,
                        "placeholder": "$0.00",
                        "apiFieldName": "SoftCost",
                        "fr_placeholder": "$0.00"
                    }]
            }
        },
        "Files Upload": {},
        "Finance Options": {
            "sections": {
                "Finance Options": [
                    {
                        "name": "Finance Product",
                        "type": "dropdown",
                        "fr_name": "PRODUIT FINANCIER",
                        "required": true,
                        "placeholder": "Finance Product",
                        "apiFieldName": "FinanceProductId",
                        "fr_placeholder": "PRODUIT FINANCIER",
                        "dropdownOptions": [],
                        "dropdownOptionValueKey": "code",
                        "dropdownOptionDisplayKey": "label"
                    },
                    {
                        "name": "Term Length in Months",
                        "type": "dropdown",
                        "fr_name": "DURÉE DU MANDAT EN MOIS",
                        "required": true,
                        "placeholder": "Months",
                        "apiFieldName": "Term",
                        "fr_placeholder": "Mois",
                        "dropdownOptions": []
                    },
                    {
                        "name": "Down Payment",
                        "type": "text",
                        "fr_name": "ACOMPTE",
                        "required": true,
                        "placeholder": "Down Payment",
                        "apiFieldName": "DownPayment",
                        "fr_placeholder": "ACOMPTE"
                    },
                    {
                        "name": "Total Asset Cost",
                        "type": "text",
                        "fr_name": "COÛT TOTAL DE L'ACTIF",
                        "required": false,
                        "hideField": false,
                        "isEditable": false,
                        "placeholder": "Total Asset Cost",
                        "apiFieldName": "TotalAssetCost",
                        "fr_placeholder": "COÛT TOTAL DE L'ACTIF"
                    },
                    {
                        "name": "Total Amount Financed",
                        "type": "text",
                        "fr_name": "MONTANT TOTAL FINANCÉ",
                        "required": false,
                        "hideField": false,
                        "isEditable": false,
                        "placeholder": "Total Amount Financed",
                        "apiFieldName": "TotalAmountFinanced",
                        "fr_placeholder": "MONTANT TOTAL FINANCÉ"
                    },
                    {
                        "name": "Is the asset being used for Rental purposes?",
                        "type": "dropdown",
                        "fr_name": "L'actif est-il utilisé à des fins de location ?",
                        "required": true,
                        "toolTipMsg": "",
                        "placeholder": "Yes Or No",
                        "apiFieldName": "IsRental",
                        "fr_toolTipMsg": "",
                        "fr_placeholder": "Oui ou non",
                        "dropdownOptions": [
                            {
                                "label": "Yes",
                                "value": "Yes"
                            },
                            {
                                "label": "No",
                                "value": "No"
                            }
                        ]
                    }
                ]
            }
        },
        "Asset Information": {
            "sections": {
                "Asset Information": [
                    {
                        "name": "New Or Used",
                        "type": "dropdown",
                        "fr_name": "NEUF OU USAGÉ",
                        "required": true,
                        "placeholder": "New Or Used",
                        "apiFieldName": "IsNew",
                        "fr_placeholder": "NEUF OU USAGÉ",
                        "dropdownOptions": [
                            {
                                "label": "New",
                                "value": "New"
                            },
                            {
                                "label": "Used",
                                "value": "Used"
                            }
                        ]
                    },
                    {
                        "name": "Equipment Type",
                        "type": "dropdown",
                        "fr_name": "Type d'équipement",
                        "required": true,
                        "placeholder": "Equipment Type",
                        "apiFieldName": "EquipmentType",
                        "fr_placeholder": "Type d'équipement",
                        "dropdownOptions": []
                    },
                    {
                        "name": "Equipment Description",
                        "type": "text",
                        "fr_name": "Description de l'équipement",
                        "required": true,
                        "placeholder": "Equipment Description",
                        "apiFieldName": "Description",
                        "fr_placeholder": "Description de l'équipement",
                    },
                    {
                        "name": "Equipment Manufacturer",
                        "type": "dropdown",
                        "fr_name": "Fabricant d'équipement",
                        "required": true,
                        "placeholder": "Equipment Manufacturer",
                        "apiFieldName": "Manufacturer",
                        "fr_placeholder": "Fabricant d'équipement",
                        "dropdownOptions": []
                    },
                    {
                        "name": "Model",
                        "type": "dropdown",
                        "fr_name": "MODÈLE",
                        "required": false,
                        "hideField": false,
                        "placeholder": "Model",
                        "apiFieldName": "Model",
                        "fr_placeholder": "MODÈLE",
                        "dropdownOptions": []
                    },
                    {
                        "name": "Year",
                        "type": "text",
                        "fr_name": "ANNÉE",
                        "required": true,
                        "placeholder": "Year",
                        "apiFieldName": "Year",
                        "fr_placeholder": "ANNÉE"
                    },
                    {
                        "name": "SN/VIN#",
                        "type": "text",
                        "fr_name": "Numéro SN/VIN",
                        "required": false,
                        "hideField": false,
                        "toolTipMsg": "Please note this is an optional field. However, multiple SN / VIN # can be added for your assets, separated by commas, with a count matching the asset quantity. (e.g. SN12345,SN67890,VIN234567 for 3 assets)",
                        "placeholder": "SN/VIN#",
                        "apiFieldName": "SerialNums",
                        "fr_toolTipMsg": "Veuillez noter qu'il s'agit d'un champ facultatif. Cependant, plusieurs numéros SN/VIN peuvent être ajoutés pour vos actifs, séparés par des virgules, avec un nombre correspondant à la quantité d'actifs. (par exemple SN12345,SN67890,VIN234567 pour 3 actifs)",
                        "fr_placeholder": "Numéro SN/VIN"
                    },
                    {
                        "name": "Equipment Cost (Per Unit)",
                        "type": "text",
                        "fr_name": "COÛT DE L'ÉQUIPEMENT (PAR UNITÉ)",
                        "required": true,
                        "placeholder": "Cost",
                        "apiFieldName": "EquipmentCost",
                        "fr_placeholder": "COÛT"
                    },
                    {
                        "name": "Quantity",
                        "type": "text",
                        "fr_name": "QUANTITÉ",
                        "required": true,
                        "placeholder": "Quantity",
                        "apiFieldName": "Quantity",
                        "fr_placeholder": "QUANTITÉ"
                    }
                ]
            }
        },
        "Contact Information": {
            "sections": {
                "Primary Contact": [
                    {
                        "name": "First Name",
                        "type": "text",
                        "fr_name": "PRÉNOM",
                        "required": true,
                        "placeholder": "First Name",
                        "apiFieldName": "FirstName",
                        "fr_placeholder": "PRÉNOM"
                    },
                    {
                        "name": "Last Name",
                        "type": "text",
                        "fr_name": "NOM DE FAMILLE",
                        "required": true,
                        "placeholder": "Last Name",
                        "apiFieldName": "LastName",
                        "fr_placeholder": "NOM DE FAMILLE"
                    },
                    {
                        "name": "Suffix",
                        "type": "dropdown",
                        "fr_name": "Suffixe",
                        "required": false,
                        "placeholder": "Suffix",
                        "apiFieldName": "Suffix",
                        "fr_placeholder": "Suffixe",
                        "dropdownOptions": [
                            {
                                "label": "I",
                                "value": "I"
                            },
                            {
                                "label": "II",
                                "value": "II"
                            },
                            {
                                "label": "III",
                                "value": "III"
                            },
                            {
                                "label": "IV",
                                "value": "IV"
                            },
                            {
                                "label": "V",
                                "value": "V"
                            },
                            {
                                "label": "Sr.",
                                "value": "Sr."
                            },
                            {
                                "label": "Jr.",
                                "value": "Jr."
                            }
                        ]
                    },
                    {
                        "name": "Email",
                        "type": "text",
                        "fr_name": "E-MAIL",
                        "required": true,
                        "placeholder": "Email",
                        "apiFieldName": "Email",
                        "fr_placeholder": "E-MAIL"
                    },
                    {
                        "name": "Cell Phone Number",
                        "type": "text",
                        "fr_name": "NUMÉRO DE TÉLÉPHONE PORTABLE",
                        "required": true,
                        "placeholder": "Phone Number",
                        "apiFieldName": "PhoneNumber",
                        "fr_placeholder": "NUMÉRO DE TÉLÉPHONE PORTABLE",
                        "startAdornment": "+1"
                    },
                    {
                        "name": "Business Association",
                        "type": "dropdown",
                        "fr_name": "Association d'entreprises",
                        "required": true,
                        "placeholder": "Select",
                        "apiFieldName": "CustomerIndex",
                        "fr_placeholder": "Sélectionner",
                        "dropdownOptions": []
                    },
                    {
                        "name": "Title",
                        "type": "dropdown",
                        "fr_name": "Titre",
                        "required": false,
                        "placeholder": "Title",
                        "apiFieldName": "Title",
                        "fr_placeholder": "Titre",
                        "dropdownOptions": []
                    },
                    {
                        "name": "Doc Signer",
                        "type": "dropdown",
                        "fr_name": "SIGNATEUR DE DOCUMENT",
                        "required": true,
                        "toolTipMsg": "This is the person who is going to sign the documents",
                        "placeholder": "Doc Signer",
                        "apiFieldName": "DocSigner",
                        "fr_toolTipMsg": "C'est la personne qui va signer les documents",
                        "fr_placeholder": "SIGNATEUR DE DOCUMENT",
                        "dropdownOptions": [
                            {
                                "label": "Yes",
                                "value": "Yes"
                            },
                            {
                                "label": "No",
                                "value": "No"
                            }
                        ]
                    }
                ]
            }
        },
        "Customer Information": {
            "sections": {
                "Address Details": [
                    {
                        "name": "Main Address",
                        "type": "text",
                        "fr_name": "ADRESSE PRINCIPALE",
                        "required": true,
                        "maxLength": 100,
                        "toolTipMsg": "",
                        "placeholder": "Address",
                        "apiFieldName": "Address",
                        "fr_placeholder": "ADRESSE PRINCIPALE"
                    },
                    {
                        "name": "Country",
                        "type": "dropdown",
                        "fr_name": "PAYS",
                        "required": true,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "Country",
                        "apiFieldName": "Country",
                        "fr_placeholder": "PAYS",
                        "dropdownOptions": [],
                        "dropdownOptionValueKey": "code",
                        "dropdownOptionDisplayKey": "country"
                    },
                    {
                        "name": "City",
                        "type": "text",
                        "fr_name": "VILLE",
                        "required": true,
                        "maxLength": 100,
                        "toolTipMsg": "",
                        "placeholder": "City",
                        "apiFieldName": "City",
                        "fr_placeholder": "VILLE"
                    },
                    {
                        "name": "State/Province Of Incorporation",
                        "type": "dropdown",
                        "fr_name": "ÉTAT/PROVINCE DE CONSTITUTION",
                        "required": true,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "State",
                        "apiFieldName": "State",
                        "fr_placeholder": "ÉTAT/PROVINCE",
                        "dropdownOptions": [],
                        "dropdownOptionValueKey": "code",
                        "dropdownOptionDisplayKey": "state"
                    },
                    {
                        "name": "Zip/Postal Code",
                        "type": "text",
                        "fr_name": "Zip/Code postal",
                        "required": true,
                        "maxLength": 9,
                        "toolTipMsg": "",
                        "placeholder": "Zip/Postal Code",
                        "apiFieldName": "PostalCode",
                        "fr_placeholder": "ZIP/CODE POSTAL"
                    },
                    {
                        "name": "Email",
                        "type": "text",
                        "fr_name": "E-MAIL",
                        "required": true,
                        "maxLength": 60,
                        "toolTipMsg": "",
                        "placeholder": "Email",
                        "apiFieldName": "Email",
                        "fr_placeholder": "E-MAIL"
                    },
                    {
                        "name": "Phone Number",
                        "type": "text",
                        "fr_name": "NUMÉRO DE TÉLÉPHONE",
                        "required": true,
                        "maxLength": 10,
                        "toolTipMsg": "",
                        "placeholder": "Phone Number",
                        "apiFieldName": "PhoneNumber",
                        "fr_placeholder": "NUMÉRO DE TÉLÉPHONE",
                        "startAdornment": "+1"
                    }
                ],
                "Billing Address": [
                    {
                        "name": "Address",
                        "type": "text",
                        "fr_name": "ADRESSE PRINCIPALE",
                        "required": true,
                        "maxLength": 100,
                        "toolTipMsg": "",
                        "placeholder": "Address",
                        "apiFieldName": "BillingAddress",
                        "fr_placeholder": "ADRESSE PRINCIPALE"
                    },
                    {
                        "name": "Country",
                        "type": "dropdown",
                        "fr_name": "PAYS",
                        "required": true,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "Country",
                        "apiFieldName": "BillingCountry",
                        "fr_placeholder": "PAYS",
                        "dropdownOptions": [],
                        "dropdownOptionValueKey": "code",
                        "dropdownOptionDisplayKey": "country"
                    },
                    {
                        "name": "City",
                        "type": "text",
                        "fr_name": "VILLE",
                        "required": true,
                        "maxLength": 100,
                        "toolTipMsg": "",
                        "placeholder": "City",
                        "apiFieldName": "BillingCity",
                        "fr_placeholder": "VILLE"
                    },
                    {
                        "name": "State/Province Of Incorporation",
                        "type": "dropdown",
                        "fr_name": "ÉTAT/PROVINCE DE CONSTITUTION",
                        "required": true,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "State",
                        "apiFieldName": "BillingState",
                        "fr_placeholder": "STATE/PROVINCE",
                        "dropdownOptions": [],
                        "dropdownOptionValueKey": "code",
                        "dropdownOptionDisplayKey": "state"
                    },
                    {
                        "name": "Zip/Postal Code",
                        "type": "text",
                        "fr_name": "ZIP / CODE POSTAL",
                        "required": true,
                        "maxLength": 9,
                        "toolTipMsg": "",
                        "placeholder": "Zip/Postal Code",
                        "apiFieldName": "BillingPostalCode",
                        "fr_placeholder": "ZIP / CODE POSTAL"
                    },
                    {
                        "name": "Email",
                        "type": "text",
                        "fr_name": "E-MAIL",
                        "required": true,
                        "maxLength": 60,
                        "toolTipMsg": "",
                        "placeholder": "Email",
                        "apiFieldName": "BillingEmail",
                        "fr_placeholder": "E-MAIL"
                    },
                    {
                        "name": "Phone Number",
                        "type": "text",
                        "fr_name": "NUMÉRO DE TÉLÉPHONE",
                        "required": true,
                        "maxLength": 10,
                        "toolTipMsg": "",
                        "placeholder": "Phone Number",
                        "apiFieldName": "BillingPhoneNumber",
                        "fr_placeholder": "NUMÉRO DE TÉLÉPHONE",
                        "startAdornment": "+1"
                    }
                ],
                "Business Details": [
                    {
                        "name": "Legal Registered Name",
                        "type": "text",
                        "fr_name": "NOM LÉGAL ENREGISTRÉ",
                        "required": true,
                        "maxLength": 100,
                        "toolTipMsg": "",
                        "placeholder": "Legal Registered Name",
                        "apiFieldName": "LegalRegName",
                        "fr_placeholder": "NOM LÉGAL ENREGISTRÉ"
                    },
                    {
                        "name": "DBA Name",
                        "type": "text",
                        "fr_name": "NOM DBA",
                        "required": false,
                        "hideField": false,
                        "maxLength": 100,
                        "toolTipMsg": "”Doing Business As” or Tradename",
                        "placeholder": "DBA Name",
                        "apiFieldName": "DBAName",
                        "fr_toolTipMsg": "« Faire affaire sous le nom » ou nom commercial",
                        "fr_placeholder": "NOM DBA"
                    },
                    {
                        "name": "Entity Type",
                        "type": "dropdown",
                        "fr_name": "TYPE D'ENTITÉ",
                        "required": true,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "Entity Type",
                        "apiFieldName": "CompanyCode",
                        "fr_placeholder": "TYPE D'ENTITÉ",
                        "dropdownOptions": [
                            {
                                "id": "1",
                                "Code": "C",
                                "Description": "Corporation"
                            },
                            {
                                "id": "14",
                                "Code": "SC",
                                "Description": "S Corp"
                            },
                            {
                                "id": "3",
                                "Code": "G",
                                "Description": "Federal Government"
                            },
                            {
                                "id": "5",
                                "Code": "LLC",
                                "Description": "Limited Liability Company"
                            },
                            {
                                "id": "6",
                                "Code": "LLP",
                                "Description": "Limited Liability Partnership"
                            },
                            {
                                "id": "7",
                                "Code": "N",
                                "Description": "Non Profit"
                            },
                            {
                                "id": "8",
                                "Code": "O",
                                "Description": "Other"
                            },
                            {
                                "id": "9",
                                "Code": "P",
                                "Description": "Partnership"
                            },
                            {
                                "id": "10",
                                "Code": "R",
                                "Description": "Sole Proprietorship"
                            },
                            {
                                "id": "11",
                                "Code": "S",
                                "Description": "State/Local Government"
                            },
                            {
                                "id": "13",
                                "Code": "TRUST",
                                "Description": "Trust or Estate"
                            }
                        ],
                        "dropdownOptionValueKey": "Code",
                        "dropdownOptionDisplayKey": "Description"
                    },
                    {
                        "name": "Country",
                        "type": "dropdown",
                        "fr_name": "PAYS",
                        "required": true,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "Country",
                        "apiFieldName": "CustCountry",
                        "fr_placeholder": "PAYS",
                        "dropdownOptions": [],
                        "dropdownOptionValueKey": "code",
                        "dropdownOptionDisplayKey": "country"
                    },
                    {
                        "name": "State/Province Of Incorporation",
                        "type": "dropdown",
                        "fr_name": "ÉTAT/PROVINCE DE CONSTITUTION",
                        "required": true,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "State",
                        "apiFieldName": "CustState",
                        "fr_placeholder": "STATE/PROVINCE",
                        "dropdownOptions": [],
                        "dropdownOptionValueKey": "code",
                        "dropdownOptionDisplayKey": "state"
                    },
                    {
                        "name": "Date Business Incorporated",
                        "type": "date",
                        "fr_name": "DATE DE CONSTITUTION DE L'ENTREPRISE",
                        "required": false,
                        "hideField": false,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "MM/DD/YYYY",
                        "apiFieldName": "BusinessIncorporatedDate",
                        "fr_placeholder": "MM/DD/YYYY",
                        "dropdownOptions": []
                    },
                    {
                        "name": "FED TAX ID/BN#",
                        "type": "text",
                        "fr_name": "Numéro d'identification fiscale/BN de la FED",
                        "required": true,
                        "maxLength": 15,
                        "toolTipMsg": "Federal Tax ID (US)/Business Number (Canada)",
                        "placeholder": "FED TAX ID/BN#",
                        "apiFieldName": "SSN",
                        "fr_toolTipMsg": "Numéro d'identification fiscale fédérale (États-Unis)/numéro d'entreprise (Canada)",
                        "fr_placeholder": "Numéro d'identification fiscale/BN de la FED",
                        "dropdownOptions": []
                    },
                    {
                        "name": "Gross Annual Revenue",
                        "type": "text",
                        "fr_name": "Chiffre d'affaires annuel brut",
                        "hideField": true,
                        "required": false,
                        "placeholder": "Gross Annual Revenue",
                        "apiFieldName": "GrossRevenue",
                        "fr_placeholder": "Chiffre d'affaires annuel brut"
                    },
                    {
                        "name": "Tax Exempt",
                        "type": "dropdown",
                        "fr_name": "EXEMPT D'IMPÔT",
                        "required": true,
                        "maxLength": null,
                        "toolTipMsg": "",
                        "placeholder": "",
                        "apiFieldName": "TaxExempt",
                        "fr_placeholder": "",
                        "dropdownOptions": [
                            {
                                "label": "Yes",
                                "value": "true"
                            },
                            {
                                "label": "No",
                                "value": "false"
                            }
                        ]
                    }
                ],
                "Individual Details": [
                    {
                        "name": "First Name",
                        "type": "text",
                        "fr_name": "Prénom",
                        "required": true,
                        "placeholder": "First Name",
                        "apiFieldName": "FirstName",
                        "fr_placeholder": "Prénom"
                    },
                    {
                        "name": "Middle Name",
                        "type": "text",
                        "fr_name": "Deuxième prénom",
                        "required": false,
                        "hideField": false,
                        "placeholder": "Middle Name",
                        "apiFieldName": "MiddleName",
                        "fr_placeholder": "Deuxième prénom"
                    },
                    {
                        "name": "Last Name",
                        "type": "text",
                        "fr_name": "Nom de famille",
                        "required": true,
                        "placeholder": "Last Name",
                        "apiFieldName": "LastName",
                        "fr_placeholder": "Nom de famille"
                    },
                    {
                        "name": "Suffix",
                        "type": "dropdown",
                        "fr_name": "Suffixe",
                        "required": false,
                        "placeholder": "Suffix",
                        "apiFieldName": "Suffix",
                        "fr_placeholder": "Suffixe",
                        "dropdownOptions": [
                            {
                                "label": "I",
                                "value": "I"
                            },
                            {
                                "label": "II",
                                "value": "II"
                            },
                            {
                                "label": "III",
                                "value": "III"
                            },
                            {
                                "label": "IV",
                                "value": "IV"
                            },
                            {
                                "label": "V",
                                "value": "V"
                            },
                            {
                                "label": "Sr.",
                                "value": "Sr."
                            },
                            {
                                "label": "Jr.",
                                "value": "Jr."
                            }
                        ]
                    },
                    {
                        "name": "Business Title",
                        "type": "text",
                        "fr_name": "Titre Professionnel",
                        "required": false,
                        "hideField": false,
                        "placeholder": "Business Title",
                        "apiFieldName": "BusinessTitle",
                        "fr_placeholder": "Titre Professionnel"
                    },
                    {
                        "name": "SSN#/TIN#/SIN#",
                        "type": "text",
                        "fr_name": "Numéro SSN/TIN#/SIN#",
                        "required": true,
                        "placeholder": "SSN Number",
                        "apiFieldName": "SSN",
                        "fr_placeholder": "Numéro SSN"
                    },
                    {
                        "name": "Date of Birth",
                        "type": "date",
                        "fr_name": "Date de naissance",
                        "required": true,
                        "placeholder": "Date of Birth",
                        "apiFieldName": "DOB",
                        "fr_placeholder": "Date de naissance"
                    }
                ]
            }
        }
    },
};
;
;
export default constants;
