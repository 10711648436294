var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Box, IconButton, Stack, Typography, Checkbox, Divider, CardMedia, Card, Grid, Button, SwipeableDrawer, InputLabel, FormControlLabel, FormGroup } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import './../../assets/scss/dealerModule.scss';
import { BackArrowIcon } from '../../components/icons';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { NavigationConfig } from '../../utils/navigation_config';
import TextField from '../../components/FormComponents/TextField';
import SelectDD from '../../components/FormComponents/SelectDD';
import AutoCompleteMultiSelectDD from '../../components/FormComponents/AutoCompleteMulitSelectDD';
import { api_service } from '../../api/api_service';
import { api_urls } from '../../api/api_urls';
import { FONT_OPTIONS, initialBlobFiles } from './DealerManagementEdit';
import _ from '../../utils/lodash';
import SvelteJSONEditor from "./JsonEditor";
import ConfirmationAndInformationPoup, { POPUP_TYPE } from '../../components/UtilityComponents/ConfirmationAndInformationPoup';
import { AppTypes } from '../../utils/commonUtilities';
import { utility } from '../../utils';
import constants from '../../config/constants';
import LandingPageBackgroundLogo from '../../assets/images/defaultBG.png';
import LandingPageLogo from '../../assets/images/Auxilior-Logo-BW-Vertical-White.png';
import LoginScreenLogo from '../../assets/images/Auxilior-Logo-BW-Horizontal-Black.png';
import HeaderLogo from '../../assets/images/Auxilior-Logo-BW-Horizontal-White.png';
import DefaultWhiteLogo from '../../assets/images/whiteBackground.svg';
import DefaultHeaderLogo from '../../assets/images/Transparent_Logo.png';
import AutoCompleteDD from '../../components/FormComponents/AutoCompleteDD';
var VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
var initialDealerValues = {
    IsActivationCodeEnabled: 1,
    defaultActivationCode: '',
    IsAnalyticsEnabled: 1,
    IsCreditAppEnabled: 1,
    IsLOCAppEnabled: 1,
    IsTakeDownAppEnabled: 1,
    IsQuoteToolEnabled: 1,
    IsReqDocEnabled: 1,
    IsSendAppEnabled: 1,
    IsSeasonalPayment: 1,
    IsAppGurSectionRequired: 0,
    IsDefaultLandingLogo: 0,
    IsDefaultLoginLogo1: 0,
    IsDefaultLoginLogo2: 0,
    IsDefaultLoginBackground: 0,
    FontStyle: '',
    LandingLogo: '',
    LandingLogoUrl: '',
    LoginLogo1: '',
    LoginLogo1Url: '',
    LoginLogo2: '',
    LoginLogo2Url: '',
    LoginBackground: '',
    LoginBackgroundUrl: '',
    PrimaryThemeColor: '#000',
    SecondaryThemeColor: '#8D868B',
    Name: '',
    Domain: '',
    PortalType: '',
    ProgramIds: [],
    RateCard: '',
    FAQ: ''
};
function dealerManagementCreate() {
    var _this = this;
    var _a, _b, _c, _d, _e, _f;
    var _g = useState(__assign({}, initialDealerValues)), dealerData = _g[0], setDealerData = _g[1];
    var _h = React.useState([]), programsList = _h[0], setProgramsList = _h[1];
    var _j = React.useState(__assign({}, initialBlobFiles)), blobFiles = _j[0], setBlobFiles = _j[1];
    var _k = React.useState({ Name: '', Domain: '', ProgramIds: '', FontStyle: '', defaultActivationCode: '', PortalType: '' }), errors = _k[0], setErrors = _k[1];
    var _l = useState(false), openConfirmationPopup = _l[0], setOpenConfirmationPopup = _l[1];
    var _m = useState(false), openUnsavedChangesPopup = _m[0], setOpenUnsavedChangesPopup = _m[1];
    var _o = useState(false), openSuccessPopup = _o[0], setOpenSuccessPopup = _o[1];
    var navConfig = NavigationConfig;
    var _p = useState({}), drawerState = _p[0], setDrawerState = _p[1];
    var _q = useState({
        json: __assign({}, constants.NorAppObjJSON)
    }), norJsonContent = _q[0], setNorJsonContent = _q[1];
    var _r = useState({
        json: __assign({}, constants.LocAppObjJSON)
    }), locJsonContent = _r[0], setLocJsonContent = _r[1];
    var _s = useState({
        json: __assign({}, constants.SchAppObjJSON)
    }), schJsonContent = _s[0], setSchJsonContent = _s[1];
    var _t = useState({}), defaultImageBlobs = _t[0], setDefaultImageBlobs = _t[1];
    var defaultImages = [
        { name: 'LandingLogo', path: HeaderLogo, fileName: 'Auxilior-Logo-BW-Horizontal-White.png' },
        { name: 'LoginLogo1', path: LandingPageLogo, fileName: 'Auxilior-Logo-BW-Horizontal-White.png' },
        { name: 'LoginLogo2', path: LoginScreenLogo, fileName: 'Auxilior-Logo-BW-Vertical-Black.png' },
        { name: 'LoginBackground', path: LandingPageBackgroundLogo, fileName: 'defaultBG.png' },
    ];
    var navigate = useNavigate();
    var goBackHandler = function () {
        setOpenUnsavedChangesPopup(true);
    };
    var jsonToFormData = function (json, formData, parentKey) {
        if (formData === void 0) { formData = null; }
        if (parentKey === void 0) { parentKey = ''; }
        if (!formData) {
            formData = new FormData();
        }
        var _loop_1 = function (key) {
            if (json.hasOwnProperty(key)) {
                var nestedKey_1 = parentKey ? "".concat(parentKey, "[").concat(key, "]") : key;
                if (typeof json[key] === 'object' && !Array.isArray(json[key]) && !(json[key] instanceof File)) {
                    jsonToFormData(json[key], formData, nestedKey_1);
                }
                else if (Array.isArray(json[key])) {
                    json[key].forEach(function (item, index) {
                        var arrayKey = "".concat(nestedKey_1, "[").concat(index, "]");
                        if (typeof item === 'object' && !(item instanceof File)) {
                            jsonToFormData(item, formData, arrayKey);
                        }
                        else {
                            formData.append(arrayKey, item);
                        }
                    });
                }
                else {
                    formData.append(nestedKey_1, json[key]);
                }
            }
        };
        for (var key in json) {
            _loop_1(key);
        }
        return formData;
    };
    var isValidPortalData = function () {
        var updatedErrors = __assign({}, errors);
        for (var key in errors) {
            var errorMsg = validateField(key, dealerData[key]);
            updatedErrors[key] = errorMsg;
        }
        console.log('isValidPortalData ~ updatedErrors:', updatedErrors);
        setErrors(updatedErrors);
        var isValid = Object.values(updatedErrors).every(function (val) { return val === ''; });
        if (isValid && isJsonValid()) {
            setOpenConfirmationPopup(true);
        }
    };
    var handleSaveDealerConfiguration = function () {
        var __dealerData = __assign({}, dealerData);
        var _programIds = dealerData.ProgramIds.map(function (programObj) {
            return parseInt(programObj.value);
        });
        __dealerData.ProgramIds = _programIds;
        for (var keyname in __dealerData) {
            if (__dealerData[keyname] === '' || __dealerData[keyname] === null) {
                delete __dealerData[keyname];
            }
        }
        var __payload = _.cloneDeep(__dealerData);
        var formData = jsonToFormData(__payload);
        api_service.post({
            endPoint: api_urls.CREATE_DEALER,
            headerType: api_service.ContentHeaders.FormData,
            payLoad: formData,
            showLoader: true
        }).then(function (res) {
            var _a;
            if ((res === null || res === void 0 ? void 0 : res.data) && res.data.statusCode === 200) {
                handleSaveJsonData(res.data.data.VendorId);
            }
            else {
                utility.alert((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.message);
            }
        }).catch(function (err) {
            console.log('handleSaveDealerConfiguration ~ err:', err);
        });
    };
    var fetchProgramInfo = function () {
        api_service.get({
            endPoint: api_urls.PROGRAMS_LIST,
        }).then((function (res) {
            var _a;
            if (res.data && res.data.statusCode === 200) {
                var response = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data;
                if (response) {
                    var programOptions = response.map(function (programObj) { return ({
                        label: programObj.Name,
                        value: programObj.Id
                    }); });
                    setProgramsList(programOptions);
                }
            }
            else {
                console.log('res error', res.data);
            }
        })).catch(function (error) {
            console.log('Error:', error);
        });
    };
    var validateField = function (name, value) {
        if ((name === 'defaultActivationCode' && !Boolean(dealerData === null || dealerData === void 0 ? void 0 : dealerData.IsActivationCodeEnabled) && !value) || (name === 'ProgramIds' && !value.length) || (name !== 'defaultActivationCode' && name !== 'ProgramIds' && !value)) {
            return "This field is required";
        }
        if (name === 'Domain') {
            var urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
            return urlRegex.test(value) ? '' : 'Please enter a valid Domain';
        }
        return '';
    };
    var handleInputBlur = function (e, values) {
        var _a = e.target, name = _a.name, value = _a.value;
        var updatedErrors = __assign({}, errors);
        var errorMsg = validateField(name, name === 'ProgramIds' ? dealerData.ProgramIds : value);
        updatedErrors[name] = errorMsg;
        setErrors(updatedErrors);
    };
    var handleOnValChange = function (e, values) {
        var _a = e.target, name = _a.name, value = _a.value;
        console.log('handleOnValChange ~ value:', value);
        if (name === 'Domain') {
            value = value.replace(/[^a-zA-Z0-9:/._-]/g, '');
        }
        setDealerData(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleProgramChange = function (e, values) {
        setDealerData(function (prevState) { return (__assign(__assign({}, prevState), { ProgramIds: values })); });
    };
    var handleCheckBoxChange = function (event) {
        var _a;
        var _b = event.target, name = _b.name, checked = _b.checked;
        if (name === 'IsActivationCodeEnabled' && !checked) {
            setErrors(function (prevState) { return (__assign(__assign({}, prevState), { defaultActivationCode: '' })); });
        }
        setDealerData(__assign(__assign({}, dealerData), (_a = {}, _a[name] = checked ? 1 : 0, _a.defaultActivationCode = '', _a)));
    };
    var handleFileRemove = function (fileName) {
        setBlobFiles(function (prevData) {
            var _a;
            return (__assign(__assign({}, prevData), (_a = {}, _a[fileName] = '', _a)));
        });
        setDealerData(function (prevData) {
            var _a;
            return (__assign(__assign({}, prevData), (_a = {}, _a[fileName] = '', _a["".concat(fileName, "Url")] = '', _a)));
        });
    };
    var validateFileSizeAndName = function (fileEventData) {
        if (!fileEventData) {
            return false;
        }
        return true;
    };
    var getDefaultOptionNames = function (name) {
        switch (name) {
            case 'IsDefaultLandingLogo':
                return {
                    fileData: defaultImageBlobs === null || defaultImageBlobs === void 0 ? void 0 : defaultImageBlobs.LandingLogo,
                    fileName: 'LandingLogo'
                };
            case 'IsDefaultLoginLogo1':
                return {
                    fileData: defaultImageBlobs === null || defaultImageBlobs === void 0 ? void 0 : defaultImageBlobs.LoginLogo1,
                    fileName: 'LoginLogo1'
                };
            case 'IsDefaultLoginLogo2':
                return {
                    fileData: defaultImageBlobs === null || defaultImageBlobs === void 0 ? void 0 : defaultImageBlobs.LoginLogo2,
                    fileName: 'LoginLogo2'
                };
            case 'IsDefaultLoginBackground':
                return {
                    fileData: defaultImageBlobs === null || defaultImageBlobs === void 0 ? void 0 : defaultImageBlobs.LoginBackground,
                    fileName: 'LoginBackground'
                };
            default:
                return {
                    fileData: null,
                    fileName: ''
                };
        }
    };
    var handleLogoCheckBoxes = function (event) {
        var _a = event.target, name = _a.name, checked = _a.checked;
        var _b = getDefaultOptionNames(name), fileData = _b.fileData, fileName = _b.fileName;
        if (!validateFileSizeAndName(fileData)) {
            return;
        }
        if (fileData && checked) {
            var uploadedFileUrl_1;
            uploadedFileUrl_1 = URL.createObjectURL(fileData);
            setDealerData(function (prevData) {
                var _a;
                return (__assign(__assign({}, prevData), (_a = {}, _a[fileName] = fileData, _a["".concat(fileName, "Url")] = uploadedFileUrl_1, _a[name] = 1, _a)));
            });
            setBlobFiles(function (prevData) {
                var _a;
                return (__assign(__assign({}, prevData), (_a = {}, _a[fileName] = fileData, _a)));
            });
        }
        else {
            if (!checked) {
                setDealerData(function (prevData) {
                    var _a;
                    return (__assign(__assign({}, prevData), (_a = {}, _a[fileName] = '', _a["".concat(fileName, "Url")] = '', _a[name] = 0, _a)));
                });
                setBlobFiles(function (prevData) {
                    var _a;
                    return (__assign(__assign({}, prevData), (_a = {}, _a[fileName] = '', _a)));
                });
            }
        }
    };
    var getLogoCorrespondingCheckbox = function (name) {
        switch (name) {
            case 'LandingLogo':
                return 'IsDefaultLandingLogo';
            case 'LoginLogo1':
                return 'IsDefaultLoginLogo1';
            case 'LoginLogo2':
                return 'IsDefaultLoginLogo2';
            case 'LoginBackground':
                return 'IsDefaultLoginBackground';
            default:
                return '';
        }
    };
    var fileSelectHandler = function (event) {
        var fileEventData = event.target.files[0];
        event.target.value = '';
        if (!validateFileSizeAndName(fileEventData)) {
            return;
        }
        if (event.target.name === 'RateCard' || event.target.name === 'FAQ') {
            if ((fileEventData === null || fileEventData === void 0 ? void 0 : fileEventData.type) !== 'application/pdf') {
                utility.alert('Please upload a valid file');
                return;
            }
        }
        if (['LoginBackground', 'LoginLogo2', 'LoginLogo1', 'LandingLogo'].includes(event.target.name)) {
            if (!['image/jpg', 'image/jpeg', 'image/png', 'image/svg'].includes(fileEventData === null || fileEventData === void 0 ? void 0 : fileEventData.type)) {
                utility.alert('Please upload a valid file');
                return;
            }
        }
        if (fileEventData) {
            var uploadedFileUrl = void 0;
            uploadedFileUrl = URL.createObjectURL(fileEventData);
            var __dealerData = __assign({}, dealerData);
            if (event.target.name !== 'RateCard' && event.target.name !== 'FAQ') {
                var checkboxName = getLogoCorrespondingCheckbox(event.target.name);
                __dealerData[checkboxName] = 0;
            }
            __dealerData[event.target.name] = fileEventData,
                __dealerData["".concat(event.target.name, "Url")] = uploadedFileUrl;
            setDealerData(__dealerData);
            setBlobFiles(function (prevData) {
                var _a;
                return (__assign(__assign({}, prevData), (_a = {}, _a[event.target.name] = fileEventData, _a)));
            });
        }
    };
    var handleSaveJsonData = function (vendorId) {
        var NORJsonData = null;
        if (norJsonContent === null || norJsonContent === void 0 ? void 0 : norJsonContent.text) {
            NORJsonData = JSON.parse(norJsonContent === null || norJsonContent === void 0 ? void 0 : norJsonContent.text);
        }
        else if (norJsonContent === null || norJsonContent === void 0 ? void 0 : norJsonContent.json) {
            NORJsonData = norJsonContent === null || norJsonContent === void 0 ? void 0 : norJsonContent.json;
        }
        var LOCJsonData = null;
        if (locJsonContent === null || locJsonContent === void 0 ? void 0 : locJsonContent.text) {
            LOCJsonData = JSON.parse(locJsonContent === null || locJsonContent === void 0 ? void 0 : locJsonContent.text);
        }
        else if (locJsonContent === null || locJsonContent === void 0 ? void 0 : locJsonContent.json) {
            LOCJsonData = locJsonContent === null || locJsonContent === void 0 ? void 0 : locJsonContent.json;
        }
        var SCHJsonData = null;
        if (schJsonContent === null || schJsonContent === void 0 ? void 0 : schJsonContent.text) {
            SCHJsonData = JSON.parse(schJsonContent === null || schJsonContent === void 0 ? void 0 : schJsonContent.text);
        }
        else if (schJsonContent === null || schJsonContent === void 0 ? void 0 : schJsonContent.json) {
            SCHJsonData = schJsonContent === null || schJsonContent === void 0 ? void 0 : schJsonContent.json;
        }
        var prparePayload = {
            IsNew: 1,
            VendorId: Number(vendorId),
            NORJson: NORJsonData,
            LOCJson: LOCJsonData,
            SCHJson: SCHJsonData
        };
        api_service.put({
            endPoint: api_urls.SAVE_OR_UPDATE_FIELD_CONFIG,
            payLoad: prparePayload,
            showLoader: true
        }).then(function (res) {
            var _a;
            if ((res === null || res === void 0 ? void 0 : res.data) && res.data.statusCode === 200) {
                setOpenSuccessPopup(true);
            }
            else {
                utility.alert((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.message);
            }
        }).catch(function (err) {
            console.log('handleSaveDealerConfiguration ~ err:', err);
        });
    };
    var parseJSON = function (jsonString) {
        if (jsonString === null || jsonString === void 0 ? void 0 : jsonString.json) {
            try {
                return (jsonString === null || jsonString === void 0 ? void 0 : jsonString.json) ? JSON.stringify(jsonString === null || jsonString === void 0 ? void 0 : jsonString.json) : null;
            }
            catch (error) {
                console.error("Error stringify JSON:", error);
                return false;
            }
        }
        else if (jsonString === null || jsonString === void 0 ? void 0 : jsonString.text) {
            try {
                return (jsonString === null || jsonString === void 0 ? void 0 : jsonString.text) ? JSON.parse(jsonString === null || jsonString === void 0 ? void 0 : jsonString.text) : null;
            }
            catch (error) {
                console.error("Error parsing JSON:", error);
                return false;
            }
        }
    };
    var isJsonValid = function () {
        var nor = parseJSON(norJsonContent && norJsonContent);
        var loc = parseJSON(locJsonContent && locJsonContent);
        var sch = parseJSON(schJsonContent && schJsonContent);
        return nor && Object.keys(nor).length > 0 &&
            loc && Object.keys(loc).length > 0 &&
            sch && Object.keys(sch).length > 0;
    };
    var toggleDrawer = function (anchor, open, appName) { return function () {
        var _a;
        setDrawerState(__assign(__assign({}, drawerState), (_a = {}, _a[anchor] = open ? appName : null, _a)));
    }; };
    var list = function (anchor, appName) { return (React.createElement(Box, { role: "presentation", className: "cstDrawer", p: 2.5 },
        React.createElement(Stack, { direction: 'row', alignItems: 'center', justifyContent: 'space-between', mb: 2 },
            React.createElement(Typography, { variant: 'h5', fontWeight: 700 }, "JSON Editor"),
            React.createElement(CloseIcon, { onClick: toggleDrawer(anchor, false, "close") })),
        React.createElement(Stack, { alignItems: 'center', justifyContent: 'flex-start', sx: {
                height: 'calc(100dvh - 158px)',
                overflow: 'auto',
                borderTop: '1px solid #80808036',
                borderBottom: '1px solid #80808036',
                marginBottom: '16px',
                padding: '8px 0px',
            } },
            React.createElement(SvelteJSONEditor, { onChange: function (content) {
                    switch (appName) {
                        case "Credit Application":
                            setNorJsonContent(content);
                            break;
                        case "Line of Credit Application":
                            setLocJsonContent(content);
                            break;
                        case "SCH-TakeDown Application":
                            setSchJsonContent(content);
                            break;
                        default:
                            break;
                    }
                }, content: appName === "Credit Application" ? norJsonContent : appName === "Line of Credit Application" ? locJsonContent : schJsonContent })),
        React.createElement(Stack, { direction: 'row', alignItems: 'center', justifyContent: 'flex-end', spacing: 2 },
            React.createElement(Button, { onClick: toggleDrawer(anchor, false, "close"), variant: "outlined", sx: {
                    color: '#000',
                    borderRadius: '30px', borderColor: '#000',
                } }, "Cancel"),
            React.createElement(Box, { sx: {
                    '& .MuiButtonBase-root': {
                        backgroundColor: '#000',
                        padding: '6px 8px',
                        borderRadius: '30px'
                    }
                } },
                React.createElement(Button, { type: "submit", variant: "contained", color: "primary", fullWidth: true, className: "commonBtn ", onClick: toggleDrawer(anchor, false, "close"), disabled: isJsonValid() ? false : true }, "Save"))))); };
    var getSelectedValue = useCallback(function (dropdownValue) {
        var _a;
        if (dropdownValue) {
            var _descObj = (_a = constants.PortalTypes) === null || _a === void 0 ? void 0 : _a.find(function (descObj) { return descObj.value == dropdownValue; });
            return _descObj;
        }
        return dropdownValue;
    }, [dealerData.PortalType]);
    useEffect(function () {
        fetchProgramInfo();
    }, []);
    useEffect(function () {
        var fetchImages = function () { return __awaiter(_this, void 0, void 0, function () {
            var blobs, _i, defaultImages_1, image, response, blob, file, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        blobs = {};
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 7, , 8]);
                        _i = 0, defaultImages_1 = defaultImages;
                        _a.label = 2;
                    case 2:
                        if (!(_i < defaultImages_1.length)) return [3, 6];
                        image = defaultImages_1[_i];
                        return [4, fetch(image.path)];
                    case 3:
                        response = _a.sent();
                        if (!response.ok) {
                            throw new Error("HTTP error! Status: ".concat(response.status));
                        }
                        return [4, response.blob()];
                    case 4:
                        blob = _a.sent();
                        file = new File([blob], image.fileName, { type: blob.type });
                        blobs[image.name] = file;
                        _a.label = 5;
                    case 5:
                        _i++;
                        return [3, 2];
                    case 6:
                        setDefaultImageBlobs(blobs);
                        return [3, 8];
                    case 7:
                        error_1 = _a.sent();
                        return [2, error_1];
                    case 8: return [2];
                }
            });
        }); };
        fetchImages();
    }, []);
    return (React.createElement(Box, { className: "dealer-view-container" },
        React.createElement(ConfirmationAndInformationPoup, { popupType: POPUP_TYPE.UNSAVED_DATA_WARNING, titleText: 'Are you sure?', message: "Are you sure you want to confirm creating the portal with the configuration selected?", acceptBtnText: 'Confirm', denyBtnText: 'Cancel', acceptCallBack: useCallback(function () {
                handleSaveDealerConfiguration();
                setOpenConfirmationPopup(false);
            }, [openConfirmationPopup]), denyCallBack: useCallback(function () {
                setOpenConfirmationPopup(false);
            }, []), open: openConfirmationPopup, key: "".concat(openConfirmationPopup), cancelCallBack: useCallback(function () {
                setOpenConfirmationPopup(false);
            }, []) }),
        React.createElement(ConfirmationAndInformationPoup, { popupType: POPUP_TYPE.SUCCESS_ALERT, titleText: 'Success!', message: "Portal has been created successfully.", acceptBtnText: 'Ok', acceptCallBack: useCallback(function () {
                setOpenSuccessPopup(false);
                navigate(navConfig.DealerManagement);
            }, [openConfirmationPopup]), open: openSuccessPopup, key: "".concat(openSuccessPopup) }),
        React.createElement(ConfirmationAndInformationPoup, { popupType: POPUP_TYPE.UNSAVED_DATA_WARNING, titleText: 'Quit Creation?', message: 'All the information entered will be lost. Are you sure you want to exit this section?', acceptBtnText: 'Yes', denyBtnText: 'No', acceptCallBack: function () {
                setOpenUnsavedChangesPopup(false);
                navigate(-1);
            }, denyCallBack: useCallback(function () {
                setOpenUnsavedChangesPopup(false);
            }, []), open: openUnsavedChangesPopup, key: "".concat(openUnsavedChangesPopup), cancelCallBack: useCallback(function () { return setOpenUnsavedChangesPopup(false); }, []) }),
        React.createElement(Stack, { direction: 'row', className: "dealer-view-header" },
            React.createElement(IconButton, { size: 'small', onClick: goBackHandler },
                React.createElement(BackArrowIcon, null)),
            React.createElement(Typography, null, "Create Portal Configurations")),
        React.createElement(Box, { className: "dealer-view-body" },
            React.createElement(Stack, { direction: 'row', className: "dealer-info-box" },
                React.createElement(Stack, null,
                    React.createElement(TextField, { label: 'Portal Name', name: 'Name', isMandatoryField: true, placeholder: 'Enter Name', fullWidth: true, value: dealerData.Name, onChange: handleOnValChange, onBlur: handleInputBlur, error: Boolean(errors === null || errors === void 0 ? void 0 : errors.Name), error_message: errors.Name, type: "text" })),
                React.createElement(Stack, null,
                    React.createElement(TextField, { label: 'Portal URL', name: 'Domain', isMandatoryField: true, placeholder: 'Enter URL', fullWidth: true, value: dealerData.Domain, onChange: handleOnValChange, onBlur: handleInputBlur, error: Boolean(errors.Domain), error_message: errors.Domain, type: "text" })),
                React.createElement(Stack, null,
                    React.createElement(AutoCompleteDD, { label: 'Portal Type', isMandatoryField: true, errorMessage: errors.PortalType, options: constants.PortalTypes, displayKey: 'label', valueKey: 'value', placeholder: 'Select', handleChange: handleOnValChange, selectedValue: getSelectedValue(dealerData === null || dealerData === void 0 ? void 0 : dealerData.PortalType), name: "PortalType", handleBlur: handleInputBlur }))),
            React.createElement(Box, { className: "dealer-view-body-inside" },
                React.createElement(Box, { className: "granted-apps", mb: 1 },
                    React.createElement(Stack, { className: "content-wrapper content-wrapper-edit" },
                        React.createElement(Typography, { className: 'header-txt', width: '100%' },
                            "Program Input ",
                            React.createElement(Box, { component: 'span', sx: { color: 'red' } }, "*")),
                        React.createElement(Divider, { sx: { width: '100%' } }),
                        React.createElement(Stack, { direction: 'row', justifyContent: 'space-between', width: '100%', py: 1.5, sx: {
                                '& .MuiFormControl-root': {
                                    minWidth: '320px',
                                    '& .MuiFormHelperText-root': {
                                        fontWeight: '400 !important',
                                        fontSize: '0.75rem !important',
                                    },
                                    '& .MuiAutocomplete-input': {
                                        '&::placeholder': {
                                            fontSize: '14px',
                                        }
                                    }
                                }
                            } },
                            React.createElement(AutoCompleteMultiSelectDD, { options: programsList, displayKey: 'label', valueKey: 'value', label: '', placeholder: 'Select Program', handleChange: handleProgramChange, selectedValue: dealerData === null || dealerData === void 0 ? void 0 : dealerData.ProgramIds, name: "ProgramIds", errorMessage: errors.ProgramIds, handleBlur: handleInputBlur })))),
                React.createElement(Box, { className: "granted-apps", mb: 1 },
                    React.createElement(Stack, { className: "content-wrapper content-wrapper-edit" },
                        React.createElement(Typography, { className: 'header-txt', width: '100%' }, "Features Configurations"),
                        React.createElement(Divider, { sx: { width: '100%' } }),
                        React.createElement(Stack, { width: '100%' },
                            React.createElement(Stack, { direction: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', py: 1.5 },
                                React.createElement(Typography, { className: 'header-txt', whiteSpace: 'nowrap' }, "Applications Granted"),
                                React.createElement(Box, { width: '80%' },
                                    React.createElement(Box, { display: 'flex', alignItems: 'center' },
                                        React.createElement(Typography, { className: 'editText grantFeildsSm' },
                                            React.createElement(Checkbox, { sx: { "&.Mui-checked": { color: "#000", } }, disabled: false, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null), name: 'IsCreditAppEnabled', value: dealerData.IsCreditAppEnabled, checked: dealerData.IsCreditAppEnabled === 1, onChange: handleCheckBoxChange }),
                                            "Credit Application"),
                                        React.createElement(Typography, { className: 'editText grantFeildsSm' },
                                            React.createElement(Checkbox, { sx: { "&.Mui-checked": { color: "#000", } }, disabled: false, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null), name: 'IsLOCAppEnabled', value: dealerData.IsLOCAppEnabled, checked: dealerData.IsLOCAppEnabled === 1, onChange: handleCheckBoxChange }),
                                            "Line of Credit Application"),
                                        React.createElement(Typography, { className: 'editText grantFeildsMd', sx: { display: { xs: 'none', md: 'block' } } },
                                            React.createElement(Checkbox, { sx: { "&.Mui-checked": { color: "#000", } }, disabled: false, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null), name: 'IsTakeDownAppEnabled', value: dealerData.IsTakeDownAppEnabled, checked: dealerData.IsTakeDownAppEnabled === 1, onChange: handleCheckBoxChange }),
                                            "Schedule/Takedown"),
                                        React.createElement(Typography, { className: 'editText grantFeildsSm grantFeildsSmText' },
                                            React.createElement(Checkbox, { sx: { "&.Mui-checked": { color: "#000", } }, disabled: false, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null), name: 'IsTakeDownAppEnabled', value: dealerData.IsTakeDownAppEnabled, checked: dealerData.IsTakeDownAppEnabled === 1, onChange: handleCheckBoxChange }),
                                            "Schedule / Takedown")))),
                            React.createElement(Divider, { sx: { width: '100%' } }),
                            React.createElement(Box, { className: "granted-apps", mb: 1, display: 'flex', flexWrap: 'wrap' },
                                React.createElement(Stack, { direction: 'row', justifyContent: 'flex-start !important', className: "content-wrapper content-wrapper-edit content-quote", sx: { border: 'none !important' } },
                                    React.createElement(Typography, null, "Quote tool"),
                                    React.createElement(Typography, null,
                                        React.createElement(Checkbox, { sx: { "&.Mui-checked": { color: "#000", } }, disabled: false, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null), name: 'IsQuoteToolEnabled', value: dealerData.IsQuoteToolEnabled, checked: dealerData.IsQuoteToolEnabled === 1, onChange: handleCheckBoxChange }))),
                                React.createElement(Stack, { direction: 'row', justifyContent: 'flex-start !important', className: "content-wrapper content-wrapper-edit content-quote", sx: { border: 'none !important' } },
                                    React.createElement(Typography, null, "Send Application Link"),
                                    React.createElement(Typography, null,
                                        React.createElement(Checkbox, { sx: { "&.Mui-checked": { color: "#000", } }, disabled: false, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null), name: 'IsSendAppEnabled', value: dealerData.IsSendAppEnabled, checked: dealerData.IsSendAppEnabled === 1, onChange: handleCheckBoxChange }))),
                                React.createElement(Stack, { direction: 'row', justifyContent: 'flex-start !important', className: "content-wrapper content-wrapper-edit content-quote", sx: { border: 'none !important' } },
                                    React.createElement(Typography, null, "Req Docs"),
                                    React.createElement(Typography, null,
                                        React.createElement(Checkbox, { sx: { "&.Mui-checked": { color: "#000", } }, disabled: false, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null), name: 'IsReqDocEnabled', value: dealerData.IsReqDocEnabled, checked: dealerData.IsReqDocEnabled === 1, onChange: handleCheckBoxChange }))),
                                React.createElement(Stack, { direction: 'row', justifyContent: 'flex-start !important', className: "content-wrapper content-wrapper-edit content-quote", sx: { border: 'none !important' } },
                                    React.createElement(Typography, null, "Analytics"),
                                    React.createElement(Typography, null,
                                        React.createElement(Checkbox, { sx: { "&.Mui-checked": { color: "#000", } }, disabled: false, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null), name: 'IsAnalyticsEnabled', value: dealerData.IsAnalyticsEnabled, checked: dealerData.IsAnalyticsEnabled === 1, onChange: handleCheckBoxChange }))),
                                React.createElement(Stack, { direction: 'row', justifyContent: 'flex-start !important', className: "content-wrapper content-wrapper-edit content-quote", sx: { border: 'none !important' } },
                                    React.createElement(Typography, null, "Enable Activation Code"),
                                    React.createElement(Typography, null,
                                        React.createElement(Checkbox, { sx: { "&.Mui-checked": { color: "#000", } }, disabled: false, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null), name: 'IsActivationCodeEnabled', value: dealerData.IsActivationCodeEnabled, checked: dealerData.IsActivationCodeEnabled === 1, onChange: handleCheckBoxChange }))),
                                !(dealerData === null || dealerData === void 0 ? void 0 : dealerData.IsActivationCodeEnabled) &&
                                    React.createElement(Stack, { direction: 'row', justifyContent: 'flex-start !important', className: "content-wrapper content-wrapper-edit content-quote", sx: { border: 'none !important', '& .MuiFormHelperText-root': { fontWeight: '400 !important', fontSize: '12px !important' } } },
                                        React.createElement(TextField, { label: 'Default Activation Code', name: 'defaultActivationCode', isMandatoryField: !(dealerData === null || dealerData === void 0 ? void 0 : dealerData.IsActivationCodeEnabled), placeholder: 'Enter Code', fullWidth: true, value: dealerData.defaultActivationCode, onChange: handleOnValChange, onBlur: handleInputBlur, error: Boolean(errors === null || errors === void 0 ? void 0 : errors.defaultActivationCode), error_message: errors.defaultActivationCode, type: "text" })),
                                React.createElement(Stack, { direction: 'row', justifyContent: 'flex-start !important', className: "content-wrapper content-wrapper-edit content-quote", sx: { border: 'none !important' } },
                                    React.createElement(Typography, null, "Enable Seasonal Payment"),
                                    React.createElement(Typography, null,
                                        React.createElement(Checkbox, { sx: { "&.Mui-checked": { color: "#000", } }, disabled: false, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null), name: 'IsSeasonalPayment', value: dealerData.IsSeasonalPayment, checked: dealerData.IsSeasonalPayment === 1, onChange: handleCheckBoxChange }))),
                                React.createElement(Stack, { direction: 'row', justifyContent: 'flex-start !important', className: "content-wrapper content-wrapper-edit content-quote", sx: { border: 'none !important' } },
                                    React.createElement(Typography, null, "Is Guarantor Section Required"),
                                    React.createElement(Typography, null,
                                        React.createElement(Checkbox, { sx: { "&.Mui-checked": { color: "#000", } }, disabled: false, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null), name: 'IsAppGurSectionRequired', value: dealerData.IsAppGurSectionRequired, checked: dealerData.IsAppGurSectionRequired === 1, onChange: handleCheckBoxChange }))))))),
                React.createElement(Box, { className: "granted-apps", mb: 1 },
                    React.createElement(Grid, { container: true, className: "content-wrapper content-wrapper-edit" },
                        React.createElement(Typography, { className: "header-txt" }, "Field Configuration"),
                        React.createElement(Divider, { sx: { width: '100%' } }),
                        React.createElement(Box, { width: '100%', pt: '12px' },
                            React.createElement(Grid, { container: true, spacing: 2, width: '100% !important' }, AppTypes.map(function (appName, index) { return (React.createElement(Grid, { item: true, xs: 12, sm: 4, lg: 3 },
                                React.createElement(React.Fragment, { key: index },
                                    React.createElement(Button, { variant: 'outlined', fullWidth: true, className: 'jsonBtn', onClick: toggleDrawer('right', true, appName) },
                                        React.createElement(AttachmentIcon, { sx: { paddingRight: '10px' } }),
                                        " ",
                                        appName),
                                    React.createElement(SwipeableDrawer, { anchor: 'right', open: drawerState['right'] === appName, onClose: toggleDrawer('right', false, 'close'), onOpen: toggleDrawer('right', true, 'open'), sx: {
                                            '& .MuiPaper-root': {
                                                background: '#fff',
                                                minWidth: '800px',
                                                maxWidth: '800px',
                                            }
                                        } }, list('right', appName))))); }))))),
                React.createElement(Box, { className: "granted-apps", mb: 1 },
                    React.createElement(Stack, { direction: 'column', alignItems: 'baseline !important', className: "content-wrapper content-wrapper-rate-card content-wrapper-edit" },
                        React.createElement(Typography, null, "Rate Card"),
                        React.createElement(Divider, { sx: { width: '100%' } }),
                        React.createElement(Stack, { direction: 'row', spacing: 2 },
                            React.createElement(Box, null,
                                React.createElement(Typography, { width: '100%', className: "fileUploadHeader-subheader" }, "Upload rate card for the Portal. Supported file format is PDF only."),
                                React.createElement(Box, { className: 'choose-file-input' },
                                    React.createElement(Button, { component: "label", variant: "contained", id: 'upload-img-left', className: "upload-btn" },
                                        "Choose file",
                                        React.createElement(VisuallyHiddenInput, { type: "file", name: 'RateCard', onChange: function (e) { return fileSelectHandler(e); }, accept: '.pdf' })),
                                    React.createElement(Box, { id: "custom-text" },
                                        React.createElement(Button, { component: "label", variant: "outlined", id: 'upload-img-right', sx: { padding: '0px' } },
                                            (typeof (dealerData === null || dealerData === void 0 ? void 0 : dealerData.RateCard) === 'string' ? dealerData === null || dealerData === void 0 ? void 0 : dealerData.RateCard : (_a = dealerData === null || dealerData === void 0 ? void 0 : dealerData.RateCard) === null || _a === void 0 ? void 0 : _a.name) || 'No file chosen, yet.',
                                            dealerData.RateCard &&
                                                React.createElement(CloseIcon, { sx: { marginLeft: '8px', cursor: 'pointer', fontSize: '20px' }, onClick: function () { return handleFileRemove('RateCard'); } })))))))),
                React.createElement(Box, { className: "granted-apps", mb: 1 },
                    React.createElement(Stack, { direction: 'column', alignItems: 'baseline !important', className: "content-wrapper content-wrapper-rate-card content-wrapper-edit" },
                        React.createElement(Typography, null, "FAQ"),
                        React.createElement(Divider, { sx: { width: '100%' } }),
                        React.createElement(Stack, { direction: 'row', spacing: 2 },
                            React.createElement(Box, null,
                                React.createElement(Typography, { width: '100%', className: "fileUploadHeader-subheader" }, "Upload FAQ for the Portal. Supported file format is PDF only."),
                                React.createElement(Box, { className: 'choose-file-input' },
                                    React.createElement(Button, { component: "label", variant: "contained", id: 'upload-img-left', className: "upload-btn" },
                                        "Choose file",
                                        React.createElement(VisuallyHiddenInput, { type: "file", name: 'FAQ', onChange: function (e) { return fileSelectHandler(e); }, accept: '.pdf' })),
                                    React.createElement(Box, { id: "custom-text" },
                                        React.createElement(Button, { component: "label", variant: "outlined", id: 'upload-img-right', sx: { padding: '0px' } },
                                            (typeof (dealerData === null || dealerData === void 0 ? void 0 : dealerData.FAQ) === 'string' ? dealerData === null || dealerData === void 0 ? void 0 : dealerData.FAQ : (_b = dealerData === null || dealerData === void 0 ? void 0 : dealerData.FAQ) === null || _b === void 0 ? void 0 : _b.name) || 'No file chosen, yet.',
                                            dealerData.FAQ &&
                                                React.createElement(CloseIcon, { sx: { marginLeft: '8px', cursor: 'pointer', fontSize: '20px' }, onClick: function () { return handleFileRemove('FAQ'); } })))))))),
                React.createElement(Box, { className: "granted-apps", mb: 1 },
                    React.createElement(Stack, { className: "content-wrapper content-wrapper-edit" },
                        React.createElement(Typography, { className: 'header-txt', width: '100%' },
                            "Font Selection ",
                            React.createElement(Box, { component: 'span', sx: { color: 'red' } }, "*")),
                        React.createElement(Divider, { sx: { width: '100%' } }),
                        React.createElement(Stack, { sx: {
                                '& .MuiFormControl-root': {
                                    '& .MuiInputBase-root': {
                                        background: '#fff',
                                        borderRadius: '8px',
                                    },
                                    '& p': {
                                        fontWeight: '400 !important',
                                        fontSize: '14px !important'
                                    }
                                },
                            }, className: 'font-dropdown', direction: 'row', justifyContent: 'flex-start', spacing: 2, alignItems: 'center', width: '100%', py: 1.5 },
                            React.createElement(SelectDD, { options: FONT_OPTIONS, DropDownLabel: "", placeHolder: 'Select Font Type', selectedValue: dealerData.FontStyle, handleChange: handleOnValChange, handleBlur: handleInputBlur, name: "FontStyle", errorMessage: errors.FontStyle }),
                            React.createElement(Typography, { className: 'editText' },
                                "Selected Font Type:",
                                React.createElement("b", null, dealerData.FontStyle))))),
                React.createElement(Box, { className: "granted-apps", mb: 1 },
                    React.createElement(Stack, { className: "content-wrapper content-wrapper-edit" },
                        React.createElement(Typography, { className: 'header-txt', width: '100%' }, "Logo Upload"),
                        React.createElement(Divider, { sx: { width: '100%' } }),
                        React.createElement(Stack, { direction: 'row', justifyContent: 'space-between', width: '100%', pt: 1.5, spacing: 2 },
                            React.createElement(Stack, { direction: 'row', spacing: 2 },
                                React.createElement(Box, null,
                                    React.createElement(Typography, { width: '100%', className: "fileUploadHeader defaultLogoText" },
                                        "Landing Page Background",
                                        React.createElement(FormGroup, null,
                                            React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { sx: { "&.Mui-checked": { color: "#000", } }, name: 'IsDefaultLoginBackground', checked: dealerData === null || dealerData === void 0 ? void 0 : dealerData.IsDefaultLoginBackground, onChange: function (e) { return handleLogoCheckBoxes(e); }, disabled: false, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null) }), label: "Check this box to use a default background" }))),
                                    React.createElement(Box, { className: 'choose-file-input' },
                                        React.createElement(Button, { component: "label", variant: "contained", id: 'upload-img-left', className: "upload-btn" },
                                            "Choose file",
                                            React.createElement(VisuallyHiddenInput, { type: "file", name: 'LoginBackground', onChange: function (e) { return fileSelectHandler(e); }, accept: '.jpg, .jpeg, .png, .svg' })),
                                        React.createElement(Box, { id: "custom-text" },
                                            React.createElement(Button, { component: "label", variant: "outlined", id: 'upload-img-right', sx: { padding: '0px' } },
                                                (typeof (dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginBackground) === 'string' ? dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginBackground : (_c = dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginBackground) === null || _c === void 0 ? void 0 : _c.name) || 'No file chosen, yet.',
                                                dealerData.LoginBackground &&
                                                    React.createElement(CloseIcon, { sx: { marginLeft: '8px', cursor: 'pointer', fontSize: '20px' }, onClick: function () { return handleFileRemove('LoginBackground'); } })))))),
                            (dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginBackgroundUrl) ?
                                React.createElement(Card, { sx: { maxWidth: 150, minHeight: 70, maxHeight: 70, display: 'flex', justifyContent: 'center', alignItems: 'center' } },
                                    React.createElement(CardMedia, { component: "img", alt: "No Preview", height: "auto", image: dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginBackgroundUrl })) :
                                React.createElement(Card, { sx: { maxWidth: 150, minHeight: 70, maxHeight: 70, display: 'flex', justifyContent: 'center', alignItems: 'center' } },
                                    React.createElement(CardMedia, { component: "img", alt: "No Preview", height: "auto", title: 'Default', src: DefaultWhiteLogo }))),
                        React.createElement(Stack, { direction: 'row', justifyContent: 'space-between', width: '100%', spacing: 2 },
                            React.createElement(Stack, { direction: 'row', spacing: 2 },
                                React.createElement(Box, null,
                                    React.createElement(Typography, { width: '100%', className: "fileUploadHeader defaultLogoText" },
                                        "Landing Page Logo",
                                        React.createElement(FormGroup, null,
                                            React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { sx: { "&.Mui-checked": { color: "#000", } }, name: 'IsDefaultLoginLogo1', checked: dealerData === null || dealerData === void 0 ? void 0 : dealerData.IsDefaultLoginLogo1, onChange: function (e) { return handleLogoCheckBoxes(e); }, disabled: false, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null) }), label: "Check this box to use a default logo" }))),
                                    React.createElement(Box, { className: 'choose-file-input' },
                                        React.createElement(Button, { component: "label", variant: "contained", id: 'upload-img-left', sx: { padding: '0px 10px', whiteSpace: 'nowrap' }, className: "upload-btn" },
                                            "Choose file",
                                            React.createElement(VisuallyHiddenInput, { type: "file", name: 'LoginLogo1', onChange: function (e) { return fileSelectHandler(e); }, accept: '.jpg, .jpeg, .png, .svg' })),
                                        React.createElement(Box, { id: "custom-text" },
                                            React.createElement(Button, { component: "label", variant: "outlined", id: 'upload-img-right', sx: { padding: '0px' } },
                                                (typeof (dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginLogo1) === 'string' ? dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginLogo1 : (_d = dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginLogo1) === null || _d === void 0 ? void 0 : _d.name) || 'No file chosen, yet.',
                                                dealerData.LoginLogo1 &&
                                                    React.createElement(CloseIcon, { sx: { marginLeft: '8px', cursor: 'pointer', fontSize: '20px' }, onClick: function () { return handleFileRemove('LoginLogo1'); } })))))),
                            (dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginLogo1Url) ?
                                React.createElement(Card, { sx: { maxWidth: 150, minHeight: 70, maxHeight: 70, display: 'flex', justifyContent: 'center', alignItems: 'center' } },
                                    React.createElement(CardMedia, { component: "img", alt: "No Preview", height: "auto", image: dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginLogo1Url })) :
                                React.createElement(Card, { sx: { maxWidth: 150, minHeight: 70, maxHeight: 70, display: 'flex', justifyContent: 'center', alignItems: 'center' } },
                                    React.createElement(CardMedia, { component: "img", alt: "No Preview", height: "auto", title: 'Default', src: DefaultHeaderLogo }))),
                        React.createElement(Stack, { direction: 'row', justifyContent: 'space-between', width: '100%', spacing: 2 },
                            React.createElement(Stack, { direction: 'row', spacing: 2 },
                                React.createElement(Box, null,
                                    React.createElement(Typography, { width: '100%', className: 'fileUploadHeader defaultLogoText' },
                                        "Login Screen Logo",
                                        React.createElement(FormGroup, null,
                                            React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { sx: { "&.Mui-checked": { color: "#000", } }, name: 'IsDefaultLoginLogo2', checked: dealerData === null || dealerData === void 0 ? void 0 : dealerData.IsDefaultLoginLogo2, onChange: function (e) { return handleLogoCheckBoxes(e); }, disabled: false, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null) }), label: "Check this box to use a default logo" }))),
                                    React.createElement(Box, { className: 'choose-file-input' },
                                        React.createElement(Button, { component: "label", variant: "contained", id: 'upload-img-left', sx: { padding: '0px 10px', whiteSpace: 'nowrap' }, className: "upload-btn" },
                                            "Choose file",
                                            React.createElement(VisuallyHiddenInput, { type: "file", name: 'LoginLogo2', onChange: function (e) { return fileSelectHandler(e); }, accept: '.jpg, .jpeg, .png, .svg' })),
                                        React.createElement(Box, { id: "custom-text" },
                                            React.createElement(Button, { component: "label", variant: "outlined", id: 'upload-img-right', sx: { padding: '0px' } },
                                                (typeof (dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginLogo2) === 'string' ? dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginLogo2 : (_e = dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginLogo2) === null || _e === void 0 ? void 0 : _e.name) || 'No file chosen, yet.',
                                                dealerData.LoginLogo2 &&
                                                    React.createElement(CloseIcon, { sx: { marginLeft: '8px', cursor: 'pointer', fontSize: '20px' }, onClick: function () { return handleFileRemove('LoginLogo2'); } })))))),
                            (dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginLogo2Url) ?
                                React.createElement(Card, { sx: { maxWidth: 150, minHeight: 70, maxHeight: 70, display: 'flex', justifyContent: 'center', alignItems: 'center' } },
                                    React.createElement(CardMedia, { component: "img", alt: "No Preview", height: "auto", image: dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginLogo2Url })) :
                                React.createElement(Card, { sx: { maxWidth: 150, minHeight: 70, maxHeight: 70, display: 'flex', justifyContent: 'center', alignItems: 'center' } },
                                    React.createElement(CardMedia, { component: "img", alt: "No Preview", height: "auto", title: 'Default', src: DefaultWhiteLogo }))),
                        React.createElement(Stack, { direction: 'row', justifyContent: 'space-between', width: '100%', spacing: 2 },
                            React.createElement(Stack, { direction: 'row', spacing: 2 },
                                React.createElement(Box, null,
                                    React.createElement(Typography, { width: '100%', className: 'fileUploadHeader defaultLogoText' },
                                        "Header Logo",
                                        React.createElement(FormGroup, null,
                                            React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { sx: { "&.Mui-checked": { color: "#000", } }, name: 'IsDefaultLandingLogo', checked: dealerData === null || dealerData === void 0 ? void 0 : dealerData.IsDefaultLandingLogo, onChange: function (e) { return handleLogoCheckBoxes(e); }, disabled: false, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null) }), label: "Check this box to use a default logo" }))),
                                    React.createElement(Typography, { fontSize: '12px !important', fontWeight: '400 !important', color: '#808080', lineHeight: '1 !important', padding: '4px 0px' }, "This logo will also appear on your email templates and quotations."),
                                    React.createElement(Box, { className: 'choose-file-input' },
                                        React.createElement(Button, { component: "label", variant: "contained", id: 'upload-img-left', className: "upload-btn" },
                                            "Choose file",
                                            React.createElement(VisuallyHiddenInput, { type: "file", name: 'LandingLogo', onChange: function (e) { return fileSelectHandler(e); }, accept: '.jpg, .jpeg, .png, .svg' })),
                                        React.createElement(Box, { id: "custom-text" },
                                            React.createElement(Button, { component: "label", variant: "outlined", id: 'upload-img-right', sx: { padding: '0px' } },
                                                (typeof (dealerData === null || dealerData === void 0 ? void 0 : dealerData.LandingLogo) === 'string' ? dealerData === null || dealerData === void 0 ? void 0 : dealerData.LandingLogo : (_f = dealerData === null || dealerData === void 0 ? void 0 : dealerData.LandingLogo) === null || _f === void 0 ? void 0 : _f.name) || 'No file chosen, yet.',
                                                dealerData.LandingLogo &&
                                                    React.createElement(CloseIcon, { sx: { marginLeft: '8px', cursor: 'pointer', fontSize: '20px' }, onClick: function () { return handleFileRemove('LandingLogo'); } })))))),
                            (dealerData === null || dealerData === void 0 ? void 0 : dealerData.LandingLogoUrl) ?
                                React.createElement(Card, { sx: { maxWidth: 150, minHeight: 70, maxHeight: 70, display: 'flex', justifyContent: 'center', alignItems: 'center' } },
                                    React.createElement(CardMedia, { component: "img", alt: "No Preview", height: "auto", image: dealerData === null || dealerData === void 0 ? void 0 : dealerData.LandingLogoUrl })) :
                                React.createElement(Card, { sx: { maxWidth: 150, minHeight: 70, maxHeight: 70, display: 'flex', justifyContent: 'center', alignItems: 'center' } },
                                    React.createElement(CardMedia, { component: "img", alt: "No Preview", height: "auto", title: 'Default', src: DefaultHeaderLogo }))))),
                React.createElement(Box, { className: "granted-apps", mb: 1 },
                    React.createElement(Stack, { className: 'content-wrapper content-wrapper-edit' },
                        React.createElement(Typography, { className: 'header-txt', width: '100%' }, "Theme Configuration"),
                        React.createElement(Divider, { sx: { width: '100%' } }),
                        React.createElement(Stack, { direction: 'row', justifyContent: 'space-between', width: '100%', alignItems: 'center', mb: '16px' },
                            React.createElement(Stack, null,
                                React.createElement(InputLabel, { sx: { color: '#000', fontSize: '14px', fontWeight: 400 } }, "Primary Theme:"),
                                React.createElement(Typography, { fontSize: '12px !important', fontWeight: '400 !important', color: '#808080', lineHeight: '1 !important', padding: '4px 0px' }, "Select a color or input a hex code to set the primary color for the header and call-to-action buttons.")),
                            "                ",
                            React.createElement(Box, null,
                                React.createElement("input", { style: { width: '140px', height: '50px' }, type: 'color', value: dealerData === null || dealerData === void 0 ? void 0 : dealerData.PrimaryThemeColor, onChange: function (e) { return setDealerData(__assign(__assign({}, dealerData), { PrimaryThemeColor: e.target.value })); } }))),
                        React.createElement(Stack, { direction: 'row', justifyContent: 'space-between', width: '100%', alignItems: 'center' },
                            React.createElement(Stack, null,
                                React.createElement(InputLabel, { sx: { color: '#000', fontSize: '14px', fontWeight: 400 } }, "Secondary Theme:"),
                                React.createElement(Typography, { fontSize: '12px !important', fontWeight: '400 !important', color: '#808080', lineHeight: '1 !important', padding: '4px 0px' }, "Select a color or input a hex code to set the secondary color for the secondary buttons.")),
                            React.createElement("input", { style: { width: '140px', height: '50px' }, type: 'color', value: dealerData === null || dealerData === void 0 ? void 0 : dealerData.SecondaryThemeColor, onChange: function (e) { return setDealerData(__assign(__assign({}, dealerData), { SecondaryThemeColor: e.target.value })); } })))))),
        React.createElement(Box, { display: "flex", justifyContent: "flex-end", sx: { background: "#F3F4F6" }, p: 2 },
            React.createElement(Button, { className: "btn-txt", sx: { background: '#14151C', color: '#fff', '&:hover': { background: '#14151c' } }, onClick: function () { isValidPortalData(); } }, "Create Portal"))));
}
export default dealerManagementCreate;
