import React, { useCallback, useEffect, useState } from 'react';
import ModuleHeader from '../../components/ModuleHeader/ModuleHeader';
import ModuleTable from '../../components/UtilityComponents/moduleTable';
import { AdminManagementCols } from '../../config/TableColumns';
import { Box } from '@mui/material';
import moment from 'moment';
import { api_service } from '../../api/api_service';
import { api_urls } from '../../api/api_urls';
import AdminModuleDrawer from './AdminModuleDrawer';
import { useSelector } from 'react-redux';
import ConfirmationAndInformationPoup, { POPUP_TYPE } from '../../components/UtilityComponents/ConfirmationAndInformationPoup';
import { utility } from '../../utils';
var AdminManagement = function () {
    var userBasicInfo = useSelector(function (store) { return store.User.userData; });
    var _a = useState(), searchText = _a[0], setSearchText = _a[1];
    var _b = useState([]), adminList = _b[0], setAdminList = _b[1];
    var _c = useState([]), freezedList = _c[0], setFreezedList = _c[1];
    var _d = useState(false), isFetching = _d[0], setIsFetching = _d[1];
    var _e = useState(), rowData = _e[0], setRowData = _e[1];
    var _f = useState(false), open = _f[0], setOpen = _f[1];
    var _g = useState(''), userAction = _g[0], setUserAction = _g[1];
    var _h = useState(false), openConfirmationPopup = _h[0], setOpenConfirmationPopup = _h[1];
    var _j = useState(false), openSuccessPopup = _j[0], setOpenSuccessPopup = _j[1];
    var getAdminList = function () {
        setIsFetching(true);
        setAdminList([]);
        api_service.get({
            endPoint: api_urls.ADMIN_LIST,
        }).then(function (res) {
            setIsFetching(false);
            if ((res === null || res === void 0 ? void 0 : res.data) && res.data.statusCode === 200) {
                var __adminList = res.data.data;
                __adminList.map(function (eachDealer, index) {
                    eachDealer.CreatedTime = moment(eachDealer.CreatedTime).tz('America/New_York');
                    eachDealer.AdminStatus = eachDealer.Status ? eachDealer.Status == 2 ? 2 : 1 : 0;
                });
                setAdminList(__adminList);
                setFreezedList(__adminList);
            }
        }).catch(function (err) { setIsFetching(false); });
    };
    var tableActionHandler = function (rowData, actionType) {
        setRowData(rowData);
        if (actionType === 'Edit') {
            setUserAction('Edit');
            setOpen(true);
        }
        else if (actionType === 'sendTempPasswordLink') {
            setOpenConfirmationPopup(true);
        }
    };
    var handleSendTemporaryPasswordLink = function () {
        api_service.post({
            endPoint: api_urls.RESEND_PASSWORD_LINK,
            payLoad: {
                AccountId: rowData === null || rowData === void 0 ? void 0 : rowData.Id
            },
            showLoader: true
        }).then(function (res) {
            var _a;
            if ((res === null || res === void 0 ? void 0 : res.data) && res.data.statusCode === 200) {
                setOpenSuccessPopup(true);
            }
            else {
                utility.alert((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.message);
            }
            setRowData(null);
        }).catch(function (err) {
            console.log("🚀 ~ handleSendTemporaryPasswordLink ~ err:", err);
        });
    };
    var handleViewDetails = function (__rowData) {
        setRowData(__rowData);
        setUserAction('View');
        setOpen(true);
    };
    var handleCreateAdmin = function () {
        setUserAction('Create');
        setOpen(true);
    };
    var handleClose = function () {
        setRowData(null);
        setOpen(false);
        setUserAction('');
    };
    useEffect(function () {
        var searchTerm = searchText === null || searchText === void 0 ? void 0 : searchText.toLowerCase();
        if (searchTerm === '') {
            setAdminList(freezedList);
        }
        else {
            var filteredResults = freezedList.filter(function (item) {
                return item.FirstName.toLowerCase().includes(searchTerm) ||
                    item.RoleDescription.toLowerCase().includes(searchTerm) ||
                    item.Email.toLowerCase().includes(searchTerm);
            });
            setAdminList(filteredResults);
        }
    }, [searchText]);
    useEffect(function () {
        getAdminList();
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(ConfirmationAndInformationPoup, { popupType: POPUP_TYPE.UNSAVED_DATA_WARNING, titleText: 'Are you sure?', message: "Are you sure you want to send the temporary password link?", acceptBtnText: 'Yes', denyBtnText: 'No', acceptCallBack: useCallback(function () {
                handleSendTemporaryPasswordLink();
                setOpenConfirmationPopup(false);
            }, [openConfirmationPopup]), denyCallBack: useCallback(function () {
                setOpenConfirmationPopup(false);
            }, []), open: openConfirmationPopup, key: "".concat(openConfirmationPopup), cancelCallBack: useCallback(function () {
                setOpenConfirmationPopup(false);
            }, []) }),
        React.createElement(ConfirmationAndInformationPoup, { popupType: POPUP_TYPE.SUCCESS_ALERT, titleText: 'Success!', message: "Temporary Password link has been sent successfully.", acceptBtnText: 'Ok', acceptCallBack: useCallback(function () {
                setOpenSuccessPopup(false);
            }, [openConfirmationPopup]), open: openSuccessPopup, key: "".concat(openSuccessPopup) }),
        React.createElement(Box, { className: "common-module-wrapper user-management-wrapper" },
            React.createElement(ModuleHeader, { moduleName: 'Admin Management', placeHolderText: 'Search by Name/Role/EMAIL', hasPortalDD: false, hasStatusDD: false, hasSearch: true, getSelectedPortal: function () { }, selectedStatus: function () { }, getSearchedText: setSearchText, isDrawerOpen: function () { return handleCreateAdmin(); }, feildWidth: 3.5 }),
            React.createElement(Box, { className: "module-common-table-wrapper" },
                React.createElement(ModuleTable, { dataToDisplay: adminList, columns: AdminManagementCols, isLoading: isFetching, actionHandler: tableActionHandler, onClickHandler: handleViewDetails, isRowClickEnabled: true, moduleName: "Admin Management" })),
            React.createElement(Box, null,
                React.createElement(AdminModuleDrawer, { open: open, handleClose: handleClose, rowData: rowData, userAction: userAction, getAdminList: getAdminList })))));
};
export default AdminManagement;
