import { PublicClientApplication } from "@azure/msal-browser";
import constants from "./constants";
var msalConfig = {
    auth: {
        clientId: constants.MICROSOFT_CLIENT_ID,
        authority: "https://login.microsoftonline.com/".concat(constants.MICROSOFT_TENANT_ID),
        redirectUri: window.location.origin + '/microsoftAuthRedirectCallback',
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true,
    },
    system: {
        allowRedirectInIframe: false,
    }
};
export var msalInstance = new PublicClientApplication(msalConfig);
