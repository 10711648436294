var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { NavigationConfig } from '../../utils/navigation_config';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import AuxCapLogo from './../../assets/images/AuxCapDashboardLogo-dark.svg';
import microsoft from './../../assets/images/microsoft.png';
import constants from '../../config/constants';
import { user_actions } from '../../actions/user_actions';
import { app_actions } from '../../actions/app_actions';
import messages from '../../utils/messages.json';
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal, useAccount } from "@azure/msal-react";
import { api_urls } from '../../api/api_urls';
import { api_service } from '../../api/api_service';
var INITIAL_FORM_VALUES = { email: "", password: "" };
var LoginScreen = function (props) {
    var _a = useState(__assign({}, INITIAL_FORM_VALUES)), userData = _a[0], setUserdata = _a[1];
    var _b = useState(__assign({}, INITIAL_FORM_VALUES)), formErrors = _b[0], setFormErrors = _b[1];
    var _c = useState(''), apiError = _c[0], setApiError = _c[1];
    var dispatch = useDispatch();
    var navConfig = NavigationConfig;
    var navigate = useNavigate();
    var _d = useMsal(), instance = _d.instance, accounts = _d.accounts;
    var account = useAccount(accounts[0] || {});
    var handleOnInputChange = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        isValidFormData(name, value);
    };
    var handleOnInputBlur = function (e) { isValidFormData(e.target.name, e.target.value); };
    var handleMicrosoftLogout = function () {
        instance.logoutPopup();
    };
    var handleMicrosoftLogin = function () { return __awaiter(void 0, void 0, void 0, function () {
        var loaderTimeout, loginResponse, tokenResponse, userInfo, error_1, tokenResponse, userInfo, popupError_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 5, 13, 14]);
                    dispatch(app_actions.showLoader());
                    loaderTimeout = setTimeout(function () {
                        dispatch(app_actions.hideLoader());
                    }, 10000);
                    return [4, instance.loginPopup({
                            scopes: ["User.Read"],
                        })];
                case 1:
                    loginResponse = _a.sent();
                    clearTimeout(loaderTimeout);
                    if (!loginResponse) return [3, 4];
                    return [4, instance.acquireTokenSilent({
                            scopes: ["User.Read"],
                            account: loginResponse.account,
                        })];
                case 2:
                    tokenResponse = _a.sent();
                    return [4, fetch("https://graph.microsoft.com/v1.0/me", {
                            headers: { Authorization: "Bearer ".concat(tokenResponse.accessToken) },
                        }).then(function (res) { return res.json(); })];
                case 3:
                    userInfo = _a.sent();
                    sendUserDetailsToBackend(userInfo);
                    _a.label = 4;
                case 4: return [3, 14];
                case 5:
                    error_1 = _a.sent();
                    if (!(error_1 instanceof InteractionRequiredAuthError)) return [3, 11];
                    _a.label = 6;
                case 6:
                    _a.trys.push([6, 9, , 10]);
                    dispatch(app_actions.showLoader());
                    return [4, instance.acquireTokenPopup({ scopes: ["User.Read"] })];
                case 7:
                    tokenResponse = _a.sent();
                    return [4, fetch("https://graph.microsoft.com/v1.0/me", {
                            headers: { Authorization: "Bearer ".concat(tokenResponse.accessToken) },
                        }).then(function (res) { return res.json(); })];
                case 8:
                    userInfo = _a.sent();
                    sendUserDetailsToBackend(userInfo);
                    return [3, 10];
                case 9:
                    popupError_1 = _a.sent();
                    setApiError(JSON.stringify(popupError_1, null, 2));
                    console.error("Token acquisition failed:", popupError_1);
                    return [3, 10];
                case 10: return [3, 12];
                case 11:
                    setApiError(JSON.stringify(error_1 === null || error_1 === void 0 ? void 0 : error_1.errorMessage, null, 2));
                    console.error("Login failed:", error_1);
                    _a.label = 12;
                case 12: return [3, 14];
                case 13:
                    dispatch(app_actions.hideLoader());
                    return [7];
                case 14: return [2];
            }
        });
    }); };
    var sendUserDetailsToBackend = function (userPayloadInfo) {
        var _a;
        var payloadObj = {
            FirstName: userPayloadInfo === null || userPayloadInfo === void 0 ? void 0 : userPayloadInfo.givenName,
            LastName: userPayloadInfo === null || userPayloadInfo === void 0 ? void 0 : userPayloadInfo.surname,
            Email: (_a = userPayloadInfo === null || userPayloadInfo === void 0 ? void 0 : userPayloadInfo.mail) !== null && _a !== void 0 ? _a : userPayloadInfo === null || userPayloadInfo === void 0 ? void 0 : userPayloadInfo.userPrincipalName,
            PhoneNumber: userPayloadInfo === null || userPayloadInfo === void 0 ? void 0 : userPayloadInfo.mobilePhone,
        };
        api_service
            .post({
            endPoint: api_urls.MICROSOFT_LOGIN,
            payLoad: payloadObj,
            showLoader: true,
        })
            .then(function (response) {
            var _a;
            if (((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.statusCode) === 200) {
                dispatch(user_actions.loginSuccess({ session: response.data.data }));
            }
            else {
                setApiError(function () { var _a; return (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.message; });
            }
        })
            .catch(function (error) {
            setApiError(function () { return error.message; });
        });
    };
    var isValidFormData = function (fieldName, value) {
        var _a;
        var _b, _c;
        if (fieldName === void 0) { fieldName = 'All'; }
        var _userData = __assign(__assign({}, userData), (_a = {}, _a[fieldName] = value, _a));
        var errorInfo = __assign({}, formErrors);
        switch (fieldName) {
            case 'All':
            case 'email':
                if ((_b = _userData.email) === null || _b === void 0 ? void 0 : _b.trim()) {
                    errorInfo.email = constants.regex.emailRegExp.test(_userData.email) ? '' : 'Please enter valid email address.';
                }
                else {
                    errorInfo.email = 'This field is required.';
                }
                if (fieldName !== 'All')
                    break;
            case 'All':
            case 'password':
                errorInfo.password = ((_c = _userData.password) === null || _c === void 0 ? void 0 : _c.trim()) ? '' : messages['This field is required'];
        }
        if (fieldName !== 'All') {
            setUserdata(function (prevData) {
                var _a;
                return (__assign(__assign({}, prevData), (_a = {}, _a[fieldName] = value, _a)));
            });
        }
        setFormErrors(function () { return (__assign({}, errorInfo)); });
        return Object.values(errorInfo).every(function (errorMsg) { return errorMsg === ''; });
    };
    return (React.createElement(Stack, null,
        React.createElement(Stack, { pb: { xs: '16px', sm: '16px', md: '24px' }, position: 'absolute', top: 50 },
            React.createElement("img", { src: AuxCapLogo, alt: "logo", width: '215px', height: '68.39px' })),
        React.createElement(Grid, { container: true, alignContent: 'flex-start' },
            React.createElement(Grid, { item: true, xs: 12, sm: 12, pb: '40px' },
                React.createElement(Typography, { fontSize: { xs: '22px', sm: '24px', md: '24px', lg: '30px' }, textAlign: 'center', className: 'heading' }, "Sign In to your account")),
            React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 12, pb: '34px' },
                React.createElement(Box, { className: 'd-flex mt-2', rowGap: 0.5, display: 'flex', flexDirection: 'column' },
                    React.createElement(Button, { fullWidth: true, variant: 'outlined', className: 'btn-txt', onClick: handleMicrosoftLogin },
                        React.createElement(Box, { display: 'flex', alignItems: 'center', gap: 1.5 },
                            "Sign In with Microsoft",
                            React.createElement("img", { src: microsoft, width: '20px' })))),
                React.createElement(Typography, { variant: 'body1', className: 'api-eeror' }, apiError)),
            React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 12 },
                React.createElement(Typography, { textAlign: 'center', onClick: function () { return navigate(navConfig.Account.NeedHelp); }, sx: { textDecoration: 'underline', cursor: 'pointer' } }, "Need Help?")))));
};
export default LoginScreen;
